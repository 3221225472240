import React, { useCallback, useEffect, useState } from "react";
import { useGetIdentity } from "react-admin";
import { Claim } from "../../../api/claim/Claim";
import { ClaimSummary } from "../../ClaimSummary";
import GridHeader from "../../GridHeader";

const AssignedClaimsTab = React.memo((props: any) => {
  const { identity } = useGetIdentity();

  const [loadedAssignedClaims, setLoadedAssignedClaims] = useState([]);
  const [isLoadingAssignedClaims, setIsLoadingAssignedClaims] = useState(false);

  const fetchAssignedClaims = useCallback(async () => {
    if (!identity) {
      return;
    }
    if (
      !identity?.id ||
      isLoadingAssignedClaims ||
      loadedAssignedClaims?.length
    ) {
      return;
    }

    setIsLoadingAssignedClaims(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/users/${identity.id}`,
        {
          method: "GET",
          headers: {
            Authorization: localStorage.getItem("credentials") || "",
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to fetch user: ${response.status}`);
      }

      const userData = await response.json();
      if (!userData.claimsAsAssignee) {
        setIsLoadingAssignedClaims(false);
      }
      setLoadedAssignedClaims(userData.claimsAsAssignee || []);
    } catch (error) {
      console.error("Error fetching assigned claims:", error);
    } finally {
      setIsLoadingAssignedClaims(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identity?.id]);

  useEffect(() => {
    if (props.tabValue === 1 && !loadedAssignedClaims?.length) {
      fetchAssignedClaims();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tabValue]);

  return (
    <div
      role="tabpanel"
      hidden={props.tabValue !== 1}
      id={`parent-tabpanel-${1}`}
      style={{ marginTop: 20 }}
    >
      <GridHeader />
      {loadedAssignedClaims && loadedAssignedClaims.length > 0
        ? loadedAssignedClaims.map((claim: Claim) => {
            return (
              <ClaimSummary
                claim={claim}
                type={"claim"}
                key={claim.id}
                refresh={() => {
                  fetchAssignedClaims();
                }}
              />
            );
          })
        : "-"}
    </div>
  );
});

export default AssignedClaimsTab;
