import {
  Card,
  CardContent,
  colors,
  Divider,
  FormControlLabel,
  Switch,
  Typography,
} from "@material-ui/core";
import { ReceiptOutlined, RedeemOutlined } from "@material-ui/icons";
import React from "react";
import { Button, LoadingIndicator } from "react-admin";
import { EnumPaymentPaymentType } from "../../../api/payment/EnumPaymentPaymentType";
import { Payment } from "../../../api/payment/Payment";
import { User } from "../../../api/user/User";
import { theme } from "../../../theme/theme";
import { numberFormatEUR } from "../../../util/CurrencyUtil";

type PaymentDataCardProps = {
  paymentDataLoaded: boolean;
  incomingPaymentsTotal: any;
  payoutsTotal: number;
  payments: any;
  deductDunningCostFeeFromPendingPayout: boolean;
  latestDunningCostExpense: any;
  deductDunningCostExpenseFromPendingPayout: boolean;
  latestDefaultInterest: any;
  record: any;
  successFeePercentage: any;
  deductDefaultInterestFromPendingPayout: boolean;
  deductExistingCreditorExtrasFromPendingPayout: boolean;
  debtistCostsTotal: number;
  creditor: User | null;
  toBePaidOutTotal: number;
  negativePayouts: Payment[];
  existingPaymentsAfterStart: Payment[];
  latestCreditorExtra: Payment;
  debtistCostsPending: number;
  paymentsBeforeLastPayoutTotal: number;
  debtistTaxTotal: number;
  debtistTaxPending: number;
  debtCollectorFeesTotal: number;
  successFeeTax: number;
  isAvailableForSuccessFee: boolean;
  relevantSuccessFee: number;
};

const PaymentDataCard = ({
  paymentDataLoaded,
  payments,
  payoutsTotal,
  incomingPaymentsTotal,
  latestDunningCostExpense,
  deductDunningCostFeeFromPendingPayout,
  deductDunningCostExpenseFromPendingPayout,
  record,
  successFeePercentage,
  deductDefaultInterestFromPendingPayout,
  latestDefaultInterest,
  deductExistingCreditorExtrasFromPendingPayout,
  debtistCostsTotal,
  paymentsBeforeLastPayoutTotal,
  creditor,
  debtistCostsPending,
  toBePaidOutTotal,
  negativePayouts,
  existingPaymentsAfterStart,
  latestCreditorExtra,
  debtistTaxTotal,
  debtistTaxPending,
  debtCollectorFeesTotal,
  successFeeTax,
  isAvailableForSuccessFee,
  relevantSuccessFee,
}: PaymentDataCardProps): JSX.Element => {
  const [includeSuccessFeeInCancellation, setIncludeSuccessFeeInCancellation] =
    React.useState<boolean>(negativePayouts.length > 0 ? false : true);
  const [disableCancellationFee, setDisableCancellationFee] =
    React.useState<boolean>(
      (creditor?.disableCancellationFee &&
        !existingPaymentsAfterStart.length) ||
        record?.stage === "Monitoring"
    );

  if (!paymentDataLoaded) {
    return <LoadingIndicator />;
  }

  const latestDunningCostFee = payments.find(
    (payment) => payment.paymentType === EnumPaymentPaymentType.DunningCostFee
  );

  const successFeeForCancellation =
    relevantSuccessFee ||
    parseFloat(
      (
        record?.originalAmountDue *
        parseFloat((successFeePercentage / 100).toFixed(2))
      ).toFixed(2)
    );

  const relevantCreditorInvoiceFees = Math.max(
    parseFloat(
      (
        Math.abs(latestDunningCostExpense?.amount || 0) +
        Math.abs(latestDunningCostFee?.amount || 0)
      )?.toFixed(2)
    ),
    0
  );

  const creditorInvoiceAmount = (
    Math.min(
      relevantCreditorInvoiceFees,
      record?.originalAmountDue < 50
        ? 10
        : record?.originalAmountDue < 250
        ? 24.9
        : relevantCreditorInvoiceFees
    ) +
    (isAvailableForSuccessFee && includeSuccessFeeInCancellation
      ? Math.abs(successFeeForCancellation)
      : 0)
  ).toFixed(2);

  return (
    <Card
      style={{
        marginBottom: 20,
      }}
    >
      <CardContent>
        <Typography variant="h6">Auszahlungen</Typography>
        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        {incomingPaymentsTotal !== 0 && (
          <div className="flex-between">
            <Typography variant="body2" style={{ fontWeight: 600 }}>
              Zahlungen erhalten:{" "}
            </Typography>
            <Typography
              variant="body2"
              style={{ color: theme.palette.success.main, fontWeight: 600 }}
            >
              {numberFormatEUR.format(Math.abs(incomingPaymentsTotal))}
            </Typography>
          </div>
        )}
        <div className="flex-between">
          <Typography
            variant="body2"
            style={{
              fontWeight: 600,
            }}
          >
            Bereits ausgezahlt:
          </Typography>
          <Typography
            variant="body2"
            style={{
              fontWeight: 600,
              color: payoutsTotal > 0 ? theme.palette.secondary.main : "",
            }}
          >
            {payoutsTotal > 0 ? numberFormatEUR.format(payoutsTotal) : "0 €"}
          </Typography>
        </div>

        {latestDunningCostFee && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 5,
            }}
          >
            <Typography
              variant="body2"
              style={
                deductDunningCostFeeFromPendingPayout
                  ? {}
                  : { textDecoration: "line-through" }
              }
            >
              RVG Gebühr:{" "}
            </Typography>
            <Typography
              variant="body2"
              style={
                deductDunningCostFeeFromPendingPayout
                  ? {}
                  : { textDecoration: "line-through" }
              }
            >
              {numberFormatEUR.format(-Math.abs(latestDunningCostFee.amount))}
            </Typography>
          </div>
        )}
        {latestDunningCostExpense && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 5,
            }}
          >
            <Typography
              variant="body2"
              style={
                deductDunningCostExpenseFromPendingPayout
                  ? {}
                  : { textDecoration: "line-through" }
              }
            >
              RVG Auslagen:{" "}
            </Typography>
            <Typography
              variant="body2"
              style={
                deductDunningCostExpenseFromPendingPayout
                  ? {}
                  : { textDecoration: "line-through" }
              }
            >
              {numberFormatEUR.format(
                -Math.abs(latestDunningCostExpense.amount)
              )}
            </Typography>
          </div>
        )}
        {latestDefaultInterest && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 5,
            }}
          >
            <Typography
              variant="body2"
              style={
                deductDefaultInterestFromPendingPayout
                  ? {}
                  : { textDecoration: "line-through" }
              }
            >
              Zinsen:{" "}
            </Typography>
            <Typography
              variant="body2"
              style={
                deductDefaultInterestFromPendingPayout
                  ? {}
                  : { textDecoration: "line-through" }
              }
            >
              {numberFormatEUR.format(-Math.abs(latestDefaultInterest.amount))}
            </Typography>
          </div>
        )}

        {latestCreditorExtra && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 5,
            }}
          >
            <Typography
              variant="body2"
              style={
                deductExistingCreditorExtrasFromPendingPayout
                  ? {}
                  : { textDecoration: "line-through" }
              }
            >
              Mahnkosten Mandant:{" "}
            </Typography>
            <Typography
              variant="body2"
              style={
                deductExistingCreditorExtrasFromPendingPayout
                  ? {}
                  : { textDecoration: "line-through" }
              }
            >
              {numberFormatEUR.format(-Math.abs(latestCreditorExtra.amount))}
            </Typography>
          </div>
        )}

        {record?.originalAmountDue > 10 &&
          debtistCostsPending > 0 &&
          Math.abs(latestDunningCostExpense?.amount || 0) +
            Math.abs(latestDunningCostFee?.amount || 0) >
            0 && (
            <>
              <div
                onDoubleClick={() => {
                  setDisableCancellationFee(false);
                }}
              >
                <Button
                  href={
                    "/#/Payment/create?claimId=" +
                    record?.id +
                    "&paymentType=Payout" +
                    "&amount=-" +
                    creditorInvoiceAmount
                  }
                  disabled={
                    disableCancellationFee || negativePayouts.length > 0
                  }
                  startIcon={<ReceiptOutlined />}
                  label={
                    disableCancellationFee
                      ? "Keine Stornogebühr"
                      : "Storno" +
                        (isAvailableForSuccessFee &&
                        includeSuccessFeeInCancellation
                          ? "+EP "
                          : " ") +
                        numberFormatEUR.format(
                          parseFloat(creditorInvoiceAmount)
                        )
                  }
                  size={"medium"}
                  style={{
                    backgroundColor:
                      !disableCancellationFee && !negativePayouts.length
                        ? theme.palette.error.dark
                        : theme.palette.grey[300],
                    minWidth: "100%",
                    marginTop: 10,
                    fontSize: 12,
                  }}
                  variant={"contained"}
                />
              </div>
              {isAvailableForSuccessFee &&
                !disableCancellationFee &&
                !negativePayouts?.length && (
                  <FormControlLabel
                    label="Erfolgsprämie"
                    value={includeSuccessFeeInCancellation}
                    onChange={(_e, checked) => {
                      setIncludeSuccessFeeInCancellation(checked);
                    }}
                    control={
                      <Switch
                        color="secondary"
                        defaultChecked={includeSuccessFeeInCancellation}
                      />
                    }
                    labelPlacement="end"
                    style={{ marginRight: 10, marginTop: 14, zoom: 0.8 }}
                  />
                )}
              {isAvailableForSuccessFee && includeSuccessFeeInCancellation && (
                <Button
                  href={
                    "/#/Payment/create?claimId=" +
                    record?.id +
                    "&paymentType=Payout" +
                    "&amount=" +
                    successFeeForCancellation +
                    "&reference=Success"
                  }
                  disabled={
                    disableCancellationFee || negativePayouts.length > 0
                  }
                  startIcon={<ReceiptOutlined />}
                  label={
                    "EP " +
                    numberFormatEUR.format(
                      Math.abs(relevantSuccessFee) ||
                        parseFloat(
                          (
                            record?.originalAmountDue *
                            parseFloat((successFeePercentage / 100).toFixed(2))
                          ).toFixed(2)
                        )
                    )
                  }
                  size={"medium"}
                  style={{
                    backgroundColor:
                      !disableCancellationFee && !negativePayouts.length
                        ? theme.palette.warning.dark
                        : theme.palette.grey[300],
                    minWidth: "100%",
                    marginTop: 10,
                    fontSize: 12,
                  }}
                  variant={"contained"}
                />
              )}
            </>
          )}

        {debtistCostsTotal > 0 && (
          <>
            <div className="flex-between" style={{ marginTop: 10 }}>
              <Typography variant="body2">Inkasso netto:</Typography>
              <Typography variant="body2">
                {numberFormatEUR.format(
                  Math.abs(debtistCostsTotal - debtistTaxTotal)
                )}
              </Typography>
            </div>

            <div className="flex-between">
              <Typography variant="body2">Inkasso offen:</Typography>
              <Typography variant="body2">
                {numberFormatEUR.format(debtistCostsPending)}
              </Typography>
            </div>

            <div className="flex-between">
              <Typography variant="body2" style={{ fontWeight: "bold" }}>
                Inkasso brutto:
              </Typography>
              <Typography variant="body2" style={{ fontWeight: "bold" }}>
                {numberFormatEUR.format(Math.abs(debtistCostsTotal))}
              </Typography>
            </div>
          </>
        )}
        {(debtistTaxTotal > 0 || debtistTaxPending > 0) && (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="body2" style={{ color: colors.grey[500] }}>
                Steuer, Inkasso offen:
              </Typography>
              <Typography variant="body2" style={{ color: colors.grey[500] }}>
                {numberFormatEUR.format(debtistTaxPending)}
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="body2" style={{ color: colors.grey[500] }}>
                Steuer, Inkasso gesamt:
              </Typography>
              <Typography variant="body2" style={{ color: colors.grey[500] }}>
                {numberFormatEUR.format(debtistTaxTotal)}
              </Typography>
            </div>
          </>
        )}
        <Divider style={{ marginTop: 10, marginBottom: 10 }} />

        <div className="flex-between">
          <Typography
            variant="body2"
            style={{
              fontWeight: 600,
              color: toBePaidOutTotal > 0 ? theme.palette.error.main : "",
              marginBottom: relevantSuccessFee ? 0 : 10,
            }}
          >
            Ausstehend:{" "}
          </Typography>
          <Typography
            variant="body2"
            style={{
              fontWeight: 600,
              color: toBePaidOutTotal > 0 ? theme.palette.error.main : "",
              marginBottom: relevantSuccessFee ? 0 : 10,
            }}
          >
            {numberFormatEUR.format(Math.max(toBePaidOutTotal, 0))}
          </Typography>
        </div>

        {!!relevantSuccessFee && !!paymentsBeforeLastPayoutTotal && (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant="body2"
              style={{
                color: toBePaidOutTotal > 0 ? theme.palette.error.main : "",
                marginBottom: 10,
              }}
            >
              exkl. {successFeePercentage}% EP brutto:{" "}
            </Typography>
            <Typography
              variant="body2"
              style={{
                color: toBePaidOutTotal > 0 ? theme.palette.error.main : "",
                marginBottom: 10,
              }}
            >
              {numberFormatEUR.format(
                Math.max(
                  parseFloat((relevantSuccessFee + successFeeTax).toFixed(2)),
                  0
                )
              )}
            </Typography>
          </div>
        )}
        {debtCollectorFeesTotal > 0 && (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant="body2"
              style={{
                color: toBePaidOutTotal > 0 ? theme.palette.error.main : "",
                marginBottom: 10,
              }}
            >
              Angefallene Prämie:&nbsp;
            </Typography>
            <Typography
              variant="body2"
              style={{
                color: toBePaidOutTotal > 0 ? theme.palette.error.main : "",
                marginBottom: 10,
              }}
            >
              {numberFormatEUR.format(debtCollectorFeesTotal)}
            </Typography>
          </div>
        )}
        <Button
          href={
            "/#/Payment/create?claimId=" +
            record?.id +
            "&amount=" +
            Math.max(toBePaidOutTotal, 0).toString() +
            "&paymentType=Payout" +
            (toBePaidOutTotal < 0 && record?.totalPending > 0
              ? "&reference=Zwischenbuchung"
              : "")
          }
          startIcon={<RedeemOutlined />}
          label={"Auszahlung"}
          style={{
            backgroundColor: theme.palette.success.dark,
            minWidth: "100%",
          }}
          size={"medium"}
          variant={"contained"}
        />
      </CardContent>
    </Card>
  );
};

export default PaymentDataCard;
