import { Tooltip } from "@material-ui/core";
import {
  Assignment,
  FileCopyOutlined,
  Gavel,
  InfoRounded,
  Mail,
  MoneyOff,
  Notifications,
  PersonAdd,
  StarRounded,
  Visibility,
} from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import {
  FunctionField,
  ReferenceField,
  SelectInput,
  SimpleForm,
  TextField,
  useDataProvider,
  useGetIdentity,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import ReactCountryFlag from "react-country-flag";
import { ADDRESS_COUNTRY_SHORT } from "../api/address/Address";
import { EnumAddressCountry } from "../api/address/EnumAddressCountry";
import { getFormattedClaimStatusChoices } from "../claim/ClaimCreate";
import { useManagers } from "../context/hook/useManagers";
import { theme } from "../theme/theme";
import { searchMapByKey } from "../util/MapUtils";
import { getInitials } from "../util/StyleUtils";

export const ClaimHeaderBar = React.memo((props: any) => {
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  return (
    <div className="claimHeaderBar">
      <div className="bar-item">
        <TextField
          style={{ fontWeight: 600 }}
          label="Reference (Internal Identifier)"
          source="reference"
        />
        <FileCopyOutlined
          style={{
            height: 12,
            cursor: "copy",
            position: "relative",
            top: 1,
          }}
          onClick={() => {
            navigator.clipboard.writeText(props.record?.reference);
            notify("Copied to clipboard", "info", null, false);
          }}
        />
      </div>
      <ReferenceField
        link="show"
        label="Creditor"
        source="creditor.id"
        reference="User"
        className="bar-item"
      >
        <CreditorSummary />
      </ReferenceField>
      <ReferenceField
        link="show"
        label="Debtor"
        source="debtor.id"
        reference="User"
        className="bar-item"
      >
        <DebtorSummary
          claim={props.record}
          hasLawyers={props.hasLawyers}
          hasInsolvencyAdministrators={props.hasInsolvencyAdministrators}
          className="bar-item"
          setUserCountry={props.setUserCountry}
        />
      </ReferenceField>

      <FunctionField
        label="Stage"
        className="bar-item"
        render={(record: any) => (
          <span
            style={{
              maxWidth: 200,
              fontWeight: 600,
              whiteSpace: "nowrap",
              background: ["Paid", "PaidOut"].includes(props.statusToShow)
                ? theme.palette.success.dark
                : props.statusToShow === "Paused"
                ? theme.palette.grey[800]
                : props.statusToShow === "Open"
                ? theme.palette.primary.main
                : ["Closed", "Cancelled"].includes(props.statusToShow)
                ? theme.palette.error.main
                : record?.stage === "Court"
                ? theme.palette.error.dark
                : record?.stage === "Precourt"
                ? theme.palette.secondary.main
                : record?.stage === "Reminder"
                ? theme.palette.info.main
                : theme.palette.info.dark,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              color: "white",
              padding: "7px 10px",
              fontSize: 12,
              borderRadius: 8,
            }}
          >
            {record?.stage === "Precourt" ? (
              <Mail style={{ height: 14 }} />
            ) : record?.stage === "Court" ? (
              <Gavel style={{ height: 14 }} />
            ) : record?.stage === "Monitoring" ? (
              <Visibility style={{ height: 14 }} />
            ) : record?.stage === "Reminder" ? (
              <Notifications style={{ height: 14 }} />
            ) : (
              <></>
            )}
            {`${record?.stage && record?.stage} - ${props.statusToShow}`}
            <SimpleForm
              toolbar={false}
              style={{ position: "absolute", left: -20 }}
            >
              <SelectInput
                source="status"
                label="Status"
                allowEmpty
                emptyText="-- back --"
                style={{ maxWidth: 180, opacity: 0 }}
                onChange={async (e) => {
                  if (e && e.target && e.target.value) {
                    if (
                      ["Cancelled", "Paid", "Closed"].includes(
                        e.target.value
                      ) &&
                      !window.confirm("Are you sure?")
                    ) {
                      return;
                    }

                    const newRecord = { ...record };
                    newRecord.status = e?.target?.value;
                    await dataProvider.update("Claim", {
                      id: record.id,
                      data: newRecord,
                      previousData: record,
                    });
                    refresh();
                  }
                }}
                choices={getFormattedClaimStatusChoices()}
                optionText="label"
                optionValue="value"
              />
            </SimpleForm>
          </span>
        )}
      />
      <ReferenceField
        link="show"
        label="Assignee"
        source="assignee.id"
        reference="User"
      >
        <FunctionField
          link="show"
          label="Assignee"
          source="assignee.id"
          reference="User"
          render={(record) => {
            return (
              <Tooltip title={record?.contactName}>
                <div className="assigned-user" style={{ margin: 0 }}>
                  {getInitials(record?.contactName)}
                </div>
              </Tooltip>
            );
          }}
        />
      </ReferenceField>
    </div>
  );
});

const DebtorSummary = ({
  claim,
  hasLawyers,
  hasInsolvencyAdministrators,
  setUserCountry,
}: any): React.ReactElement => {
  const record = useRecordContext();
  const amountOfClaims = record?.claimsAsDebtorIds?.length;
  const positionInArray = record?.claimsAsDebtorIds?.indexOf(claim?.id);

  const dataProvider = useDataProvider();
  const [country, setCountry] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const address = await dataProvider.getOne(
        "Address",
        {
          id: record?.address?.id,
        },
        {
          enabled: !!record?.address?.id,
        }
      );
      if (address?.data?.country) {
        setCountry(address?.data?.country);
        setUserCountry(address?.data?.country);
      }
    };
    if (!country) {
      fetchData();
    }
  }, [
    country,
    dataProvider,
    record?.address?.id,
    record?.phone,
    setUserCountry,
  ]);

  const iso2CoutryCode = searchMapByKey(
    ADDRESS_COUNTRY_SHORT,
    country as EnumAddressCountry
  );

  return (
    <>
      <FunctionField
        label="BusinessType"
        style={{ color: theme.palette.error.main }}
        render={(record: any) => (
          <span
            title="Business Type"
            style={{
              color:
                record?.businessType === "Consumer" ? "#995200" : "#000099",
              background:
                record?.businessType === "Consumer" ? "#ffc480" : "#d1d1ff",
              padding: "5px 8px",
              marginRight: 8,
              fontSize: 12,
              fontWeight: "bold",
              borderRadius: 8,
            }}
          >
            {record?.businessType === "Consumer" ? "B2C" : "B2B"}
          </span>
        )}
      />
      <FunctionField
        label="Name"
        render={(record: any) => (
          <span
            style={{
              color: theme.palette.error.dark,
              fontWeight: 700,
            }}
          >
            {iso2CoutryCode && iso2CoutryCode !== "de" && (
              <ReactCountryFlag
                countryCode={iso2CoutryCode}
                svg
                style={{ marginRight: 4 }}
              />
            )}
            {record?.businessName || record?.contactName}
            {record?.comment && (
              <Tooltip title={record.comment}>
                <InfoRounded
                  style={{
                    height: 18,
                    position: "relative",
                    top: 4,
                    color: theme.palette.primary.main,
                  }}
                />
              </Tooltip>
            )}
          </span>
        )}
      />
      {amountOfClaims && amountOfClaims > 1 && (
        <span
          title="Gesamtanzahl Akten"
          style={{
            background: theme.palette.secondary.main,
            color: "white",
            padding: "5px 10px",
            marginLeft: 10,
            fontSize: 12,
            fontWeight: "bold",
            borderRadius: 25,
          }}
        >
          <Assignment
            style={{
              height: 12,
              width: 12,
              marginRight: 2,
              position: "relative",
              top: 2,
            }}
          />
          {(positionInArray > 0 ? positionInArray + 1 : "1") +
            " / " +
            amountOfClaims}
        </span>
      )}
      {hasLawyers && (
        <span
          title="Anwalt"
          style={{
            background: theme.palette.error.dark,
            color: "white",
            padding: 5,
            marginLeft: 3,
            fontSize: 12,
            fontWeight: "bold",
            borderRadius: 25,
          }}
        >
          <Gavel
            style={{
              height: 12,
              width: 12,
              position: "relative",
              top: 2,
            }}
          />
        </span>
      )}
      {hasInsolvencyAdministrators && (
        <span
          title="Insolvenzverwalter"
          style={{
            background: theme.palette.error.dark,
            color: "white",
            padding: 5,
            marginLeft: 3,
            fontSize: 12,
            fontWeight: "bold",
            borderRadius: 25,
          }}
        >
          <MoneyOff
            style={{
              height: 12,
              width: 12,
              position: "relative",
              top: 2,
            }}
          />
        </span>
      )}
      {claim?.contactsIds?.length > 0 ? (
        <span
          title="Weitere Kontakte"
          style={{
            background: theme.palette.error.main,
            color: "white",
            padding: 5,
            marginLeft: 3,
            fontSize: 12,
            fontWeight: "bold",
            borderRadius: 8,
          }}
        >
          <PersonAdd
            style={{
              height: 12,
              width: 12,
              marginRight: 2,
              position: "relative",
              top: 2,
            }}
          />
          {claim?.contactsIds?.length}
        </span>
      ) : (
        ""
      )}
    </>
  );
};

const CreditorSummary = (): React.ReactElement => {
  const dataProvider = useDataProvider();
  const record = useRecordContext();
  const [language, setLanguage] = useState();
  const [integrationFlag, setIntegrationFlag] = useState<string>();

  useEffect(() => {
    const fetchData = async () => {
      const address = await dataProvider.getOne(
        "Address",
        {
          id: record?.address?.id,
        },
        {
          enabled: !!record?.address?.id,
        }
      );
      if (address?.data?.languageCountry) {
        setLanguage(address?.data?.languageCountry);
      }
      const integration = await dataProvider.getOne(
        "Integration",
        {
          id: record?.integration?.id,
        },
        {
          enabled: !!record?.integration?.id,
        }
      );
      if (integration?.data?.software) {
        setIntegrationFlag(integration?.data?.software);
      }
    };
    fetchData();
  }, [
    dataProvider,
    record?.address?.id,
    record?.integration?.id,
    record?.phone,
  ]);

  const { identity } = useGetIdentity();
  const { managers } = useManagers();

  const managerName = record?.operator?.id
    ? record?.operator?.id === identity?.id
      ? identity?.fullName
      : Object.values(managers).find(
          (manager) => manager.id === record?.operator?.id
        )?.contactName
    : "";

  const iso2CoutryCode = searchMapByKey(
    ADDRESS_COUNTRY_SHORT,
    language as EnumAddressCountry
  );

  return (
    <>
      <FunctionField
        label="Business Name"
        render={(record: any) => {
          return (
            <div
              style={{
                display: "flex",
              }}
            >
              {record?.operator?.id && (
                <Tooltip title={managerName}>
                  <div
                    style={{
                      alignItems: "center",
                      width: 35,
                      fontSize: 12,
                      fontWeight: 600,
                      borderRadius: 8,
                      lineHeight: "23px",
                      verticalAlign: "middle",
                      textAlign: "center",
                      marginRight: 12,
                    }}
                  >
                    <div className="assigned-user assigned-user--operator">
                      {getInitials(managerName)}
                    </div>
                  </div>
                </Tooltip>
              )}
              {/*
              <div
                style={{
                  alignItems: "center",
                  alignSelf: "center",
                  width: 35,
                  height: 20,
                  marginRight: 4,
                  fontSize: 12,
                  fontWeight: 600,
                  borderRadius: 8,
                  lineHeight: "20px",
                  verticalAlign: "middle",
                  textAlign: "center",
                  borderStyle: "solid",
                  borderColor: stringToHslColor(record?.businessName, 70),
                  color: stringToHslColor(record?.businessName, 30),
                  background: stringToHslColor(record?.businessName, 75),
                }}
              >
                {record?.businessName?.slice(0, 3).toUpperCase()}
              </div>
              */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: 14,
                  fontWeight: 600,
                  verticalAlign: "middle",
                  color: theme.palette.success.dark,
                }}
              >
                {iso2CoutryCode && iso2CoutryCode !== "DE" && (
                  <ReactCountryFlag
                    countryCode={iso2CoutryCode}
                    svg
                    style={{ marginRight: 4 }}
                  />
                )}
                {record?.businessName || record?.contactName}
                {record?.isVip && (
                  <Tooltip title={"VIP"}>
                    <StarRounded
                      style={{
                        marginLeft: -4,
                        marginTop: -4,
                        marginRight: -8,
                        height: 14,
                        color: theme.palette.warning.main,
                      }}
                    />
                  </Tooltip>
                )}
                {!!integrationFlag && (
                  <Tooltip title={`${integrationFlag} Integration`}>
                    <span style={{ color: theme.palette.primary.dark }}>
                      &nbsp;{`[${integrationFlag.slice(0, 3).toUpperCase()}]`}
                    </span>
                  </Tooltip>
                )}

                {record?.comment && (
                  <Tooltip title={record.comment}>
                    <InfoRounded
                      style={{ height: 18, color: theme.palette.primary.main }}
                    />
                  </Tooltip>
                )}
              </div>
            </div>
          );
        }}
      />
    </>
  );
};
