import { AttachFile } from "@material-ui/icons";
import qs from "qs";
import * as React from "react";
import { useState } from "react";
import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  ListProps,
  NullableBooleanInput,
  SearchInput,
  SelectInput,
  TextField,
  useDataProvider,
} from "react-admin";
import { useLocation } from "react-router-dom";
import Pagination from "../Components/Pagination";
import { theme } from "../theme/theme";

export const PaymentList = (props: ListProps): React.ReactElement => {
  const parsedLocation = qs.parse(useLocation().search.slice(1));
  let jsonFilter: any = {
    isPlanned: "",
    businessName: "",
  };

  if (parsedLocation.filter) {
    try {
      jsonFilter = JSON.parse(parsedLocation.filter as string);
    } catch (e) {}
  }

  const [referenceFilterValue, setReferenceFilterValue] = useState(
    jsonFilter.businessName?.toString() || undefined
  );
  const [isPlannedFilterValue, setIsPlannedFilterValue] = React.useState(
    jsonFilter.isPlanned?.toString()
  );

  const paymentFilters = [
    <SelectInput
      alwaysOn
      source="paymentType"
      label="Payment Type"
      choices={[
        { label: "CreditorInterest", value: "CreditorInterest" },
        { label: "DunningCostExpense", value: "DunningCostExpense" },
        { label: "DunningCostFee", value: "DunningCostFee" },
        { label: "DefaultInterest", value: "DefaultInterest" },
        {
          label: "ExistingCreditorExtras",
          value: "ExistingCreditorExtras",
        },
        {
          label: "ExistingPayments",
          value: "ExistingPayments",
        },
        { label: "WriteOffDiscount", value: "WriteOffDiscount" },
        { label: "DebtClearance", value: "DebtClearance" },
        { label: "PaymentRate", value: "PaymentRate" },
        { label: "Payout", value: "Payout" },
        {
          label: "DebtCollectorTax",
          value: "DebtCollectorTax",
        },
        {
          label: "DebtCollectorFee",
          value: "DebtCollectorFee",
        },
        {
          label: "DebtCollectorOutboundPosition",
          value: "DebtCollectorOutboundPosition",
        },
        {
          label: "DebtCollectorInboundPosition",
          value: "DebtCollectorInboundPosition",
        },
        {
          label: "ClaimPosition",
          value: "ClaimPosition",
        },
      ]}
      optionText="label"
      optionValue="value"
    />,
    <SearchInput
      source="reference"
      alwaysOn
      placeholder="Reference"
      autoFocus
      fullWidth
      onChange={(event) => {
        if (event.target?.value && event.target.value.length > 3) {
          setReferenceFilterValue(event?.target.value.trim());
        } else {
          setReferenceFilterValue(undefined);
        }
      }}
    />,
    <NullableBooleanInput
      source="isPlanned"
      alwaysOn
      value={isPlannedFilterValue}
      onChange={(event) => {
        setIsPlannedFilterValue(event?.target?.value);
      }}
    />,
  ];

  return (
    <List
      {...props}
      bulkActionButtons={false}
      title={"Payments"}
      filter={{
        ...(isPlannedFilterValue !== "" && isPlannedFilterValue !== undefined
          ? {
              ...{
                isPlanned: { equals: isPlannedFilterValue === "true" },
              },
            }
          : {
              ...{
                isPlanned: { not: undefined },
              },
            }),
        ...(!!referenceFilterValue
          ? {
              reference: { contains: referenceFilterValue || "" },
            }
          : {
              reference: { not: undefined },
            }),
      }}
      exporter={false}
      filters={paymentFilters}
      perPage={50}
      empty={false}
      pagination={<Pagination />}
      sort={{ field: "createdAt", order: "DESC" }}
    >
      <Datagrid
        rowClick="show"
        optimized
        style={{
          marginTop: "1rem",
        }}
      >
        <FunctionField
          label="Claim"
          emptyText="-"
          onClick={(e) => {
            e.stopPropagation();
          }}
          render={(record: any) => <ClaimAndDownloadField record={record} />}
        />
        <DateField
          label="Payment Date"
          source="paymentDate"
          locales="de-DE"
          options={{
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
            timeZone: "Europe/Berlin",
          }}
        />
        <TextField label="Payment Type" source="paymentType" />
        <FunctionField
          source="amount"
          label="Amount"
          sortable
          render={(record: any) => {
            const numberFormat = Intl.NumberFormat("de-DE", {
              style: "currency",
              currency: record?.currency || "EUR",
            });

            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  fontSize: 13,
                  padding: "0 0.5rem",
                  color: theme.palette.grey[600],
                  fontWeight: 600,
                }}
              >
                {numberFormat.format(record?.amount || 0)}
              </div>
            );
          }}
        />

        <TextField label="Reference" source="reference" />
        <DateField
          source="createdAt"
          label="Created At"
          locales="de-DE"
          options={{
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
            timeZone: "Europe/Berlin",
          }}
        />
        <DateField
          source="updatedAt"
          label="Updated At"
          locales="de-DE"
          options={{
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
            timeZone: "Europe/Berlin",
          }}
        />
      </Datagrid>
    </List>
  );
};

function handleFileClick(
  claimReference: string,
  url: string,
  saveFile?: boolean
): void {
  const authHeader = {
    headers: { Authorization: localStorage.getItem("credentials") || "" },
  };

  let urlToFetch = url
    .split("media/" + claimReference?.replaceAll("/", "-") + "/")
    .pop();
  urlToFetch = encodeURIComponent(urlToFetch || "");

  if (urlToFetch) {
    fetch(
      process.env.REACT_APP_SERVER_URL +
        "/api/claims/" +
        claimReference?.replaceAll("/", "-") +
        "/file/" +
        urlToFetch,
      authHeader
    ).then((response) => {
      if (response.ok) {
        let anchor = document.createElement("a");
        document.body.appendChild(anchor);

        response.blob().then((blobby) => {
          const objectUrl = window.URL.createObjectURL(blobby);
          anchor.href = objectUrl;
          anchor.download = urlToFetch;
          anchor.target = "_blank";
          anchor.click();

          if (!saveFile) {
            window.open(objectUrl, "_blank"); // Open the file in a new tab
          }

          window.URL.revokeObjectURL(objectUrl);
        });
      }
    });
  }
}

const ClaimAndDownloadField = ({ record }: { record: any }) => {
  const [claimRecord, setClaimRecord] = useState<any>(null);
  const dataProvider = useDataProvider();

  React.useEffect(() => {
    if (record?.claim?.id) {
      dataProvider.getOne("Claim", { id: record.claim.id }).then((response) => {
        setClaimRecord(response?.data);
      });
    }
  }, [dataProvider, record.claim.id]);

  if (!claimRecord) {
    return <span>...</span>;
  }

  return (
    <span>
      <a
        href={"/#/Claim/" + claimRecord?.id + "/show/2"}
        style={{ color: theme.palette.primary.main, textDecoration: "none" }}
      >
        {claimRecord?.reference}
      </a>
      {!!record.fileUrl ? (
        <span
          onClick={() => {
            handleFileClick(claimRecord?.reference, record.fileUrl, true);
          }}
          style={{
            color: theme.palette.primary.main,
            textDecoration: "none",
            marginLeft: 5,
            cursor: "pointer",
          }}
        >
          <AttachFile
            style={{
              verticalAlign: "middle",
              fontSize: 16,
              padding: "3px 15px",
              color: "#fff",
              background: theme.palette.secondary.main,
              borderRadius: 25,
            }}
          />
        </span>
      ) : (
        ""
      )}
    </span>
  );
};
