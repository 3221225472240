import qs from "qs";
import * as React from "react";
import { useState } from "react";
import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  ListProps,
  ReferenceField,
  SearchInput,
  SelectInput,
  TextField,
  useGetList,
} from "react-admin";
import { useLocation } from "react-router-dom";
import Pagination from "../Components/Pagination";
import { User } from "../api/user/User";
import { RolesField } from "./UserShow";

export const UserList = (props: ListProps): React.ReactElement => {
  const parsedLocation = qs.parse(useLocation().search.slice(1));
  let jsonFilter: any = {
    isPlanned: "",
  };

  if (parsedLocation.filter) {
    try {
      jsonFilter = JSON.parse(parsedLocation.filter as string);
    } catch (e) {}
  }

  const [nameFilterValue, setNameFilterValue] = useState(
    jsonFilter.name?.toString() || undefined
  );
  const [emailFilterValue, setEmailFilterValue] = useState(
    jsonFilter.email?.toString() || undefined
  );
  const [phoneFilterValue, setPhoneFilterValue] = useState(
    jsonFilter.phone?.toString() || undefined
  );
  const [managerFilterValue, setManagerFilterValue] = React.useState(
    jsonFilter.manager?.toString()
  );

  const { data: usersData } = useGetList<User>(
    "User",
    { page: 1, perPage: 25 },
    { field: "createdAt", order: "ASC" },
    {
      roles: {
        array_contains: "manager",
      },
    }
  );

  const userFilters = [
    <SearchInput
      source="name"
      alwaysOn
      placeholder="Name"
      autoFocus
      fullWidth
      style={{ minWidth: 300, minHeight: "48px" }}
      onChange={(event) => {
        if (event.target?.value && event.target.value.length > 2) {
          setNameFilterValue(event?.target.value);
        } else {
          setNameFilterValue(undefined);
        }
      }}
    />,
    <SearchInput
      source="email"
      type="email"
      alwaysOn
      placeholder="Email"
      fullWidth
      style={{ minWidth: 300, minHeight: "48px" }}
      onChange={(event) => {
        if (event.target?.value && event.target.value.length > 2) {
          setEmailFilterValue(event?.target.value);
        } else {
          setEmailFilterValue(undefined);
        }
      }}
    />,
    <SearchInput
      source="phone"
      type="phone"
      alwaysOn
      placeholder="Phone"
      fullWidth
      style={{ minWidth: 300, minHeight: "48px" }}
      onChange={(event) => {
        if (event.target?.value && event.target.value.length > 2) {
          setPhoneFilterValue(event?.target.value);
        } else {
          setPhoneFilterValue(undefined);
        }
      }}
    />,
    <SelectInput
      source="manager"
      label="Manager"
      value={managerFilterValue}
      onChange={(e) => {
        setManagerFilterValue(e.target.value);
      }}
      choices={[
        ...Object.entries(usersData).map(([_key, user]) => ({
          label: user.name,
          value: user.id,
        })),
      ]}
      optionText="label"
      optionValue="value"
      fullWidth
      style={{ minWidth: 225, width: "33.333%", minHeight: "48px" }}
    />,
  ];

  return (
    <List
      {...props}
      bulkActionButtons={false}
      title={"Users"}
      exporter={false}
      perPage={25}
      empty={false}
      filter={{
        ...(!!nameFilterValue
          ? {
              AND: nameFilterValue.split(" ").map((value) => ({
                name: { contains: value },
              })),
              name: { not: undefined },
            }
          : {
              name: { not: undefined },
            }),
        ...(!!emailFilterValue
          ? {
              email: { contains: emailFilterValue || "" },
            }
          : {
              email: { not: undefined },
            }),
        ...(!!phoneFilterValue
          ? {
              phone: { contains: phoneFilterValue || "" },
            }
          : {
              phone: { not: undefined },
            }),
        ...(managerFilterValue &&
          managerFilterValue.length && {
            ...{
              manager: { id: managerFilterValue },
            },
          }),
      }}
      filters={userFilters}
      pagination={<Pagination />}
      sort={{ field: "createdAt", order: "DESC" }}
    >
      <Datagrid
        rowClick="show"
        optimized
        size={"small"}
        style={{
          marginTop: "1rem",
        }}
      >
        <ReferenceField
          link="show"
          label="Business Name"
          source="id"
          reference="User"
        >
          <TextField source="businessName" />
        </ReferenceField>
        <ReferenceField
          link="show"
          label="Contact Name"
          source="id"
          reference="User"
        >
          <TextField source="contactName" />
        </ReferenceField>
        <TextField label="Email" source="email" />
        <TextField label="Phone" source="phone" />

        <DateField
          source="createdAt"
          label="Created At"
          locales="de-DE"
          options={{
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
            timeZone: "Europe/Berlin",
          }}
        />
        <FunctionField
          label="Roles"
          render={(record) => <RolesField record={record} />}
        />
      </Datagrid>
    </List>
  );
};
