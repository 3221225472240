import { Card, CardContent, Typography } from "@material-ui/core";
import * as React from "react";
import { Create, CreateProps } from "react-admin";

export const ServiceCallCreate = (props: CreateProps): React.ReactElement => {
  return (
    <Create {...props}>
      {/*

      ¯\_(ツ)_/¯

      */}
      <Card>
        <CardContent className="flex-center">
          <Typography variant="h6">👎 No 👎</Typography>
        </CardContent>
      </Card>
    </Create>
  );
};
