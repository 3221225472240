import AircallPhone from "aircall-everywhere";
import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

type CallLog = {
  id: string;
  payload: any;
  log: string;
  time: string;
};

type PhoneContextType = {
  showAirCallPhone: boolean;
  setShowAirCallPhone: Dispatch<SetStateAction<boolean>>;
  phoneRef: React.RefObject<any>;
  isPhoneReady: boolean;
  isLoggedIn: boolean;
  setIsPhoneReady: Dispatch<SetStateAction<boolean>>;
  statusMessage: string;
  setStatusMessage: Dispatch<SetStateAction<string>>;
  callLogs: CallLog[];
  addCallLog: (id: string, payload: any, log: string) => void;
  handleCheckLogin: () => void;
  initializeAircall: () => void;
  airCallTab: any;
  setAirCallTab: Dispatch<SetStateAction<any>>;
};

const PhoneContext = createContext<PhoneContextType>({
  showAirCallPhone: false,
  phoneRef: { current: null },
  isPhoneReady: false,
  statusMessage: "",
  callLogs: [],
  isLoggedIn: false,
  setShowAirCallPhone: () => {},
  setIsPhoneReady: () => {},
  setStatusMessage: () => {},
  addCallLog: () => {},
  handleCheckLogin: () => {},
  initializeAircall: () => {},
  airCallTab: undefined,
  setAirCallTab: () => {},
});

export const PhoneProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [showAirCallPhone, setShowAirCallPhone] = useState(false);
  const [airCallTab, setAirCallTab] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isPhoneReady, setIsPhoneReady] = useState(false);
  const [statusMessage, setStatusMessage] = useState("Aircall");
  const [callLogs, setCallLogs] = useState<CallLog[]>([]);
  const phoneRef = useRef<any>(null);

  const addCallLog = useCallback(
    (id: string, payload: any, log: string) => {
      setCallLogs((prev) => [
        ...prev,
        { id, payload, log, time: new Date().toLocaleTimeString() },
      ]);
      if (log !== statusMessage) {
        setStatusMessage(log);
      }
    },
    [statusMessage]
  );

  const handleCheckLogin = useCallback(() => {
    setIsPhoneReady(false);

    if (phoneRef.current) {
      phoneRef.current.isLoggedIn((response: boolean) => {
        setStatusMessage(response ? "Aircall ready" : "Aircall not ready");
        setIsLoggedIn(true);
        setIsPhoneReady(response);
        if (!response) {
          setStatusMessage("Login to Aircall");
        }
      });
    }
  }, []);

  const initializeAircall = useCallback(() => {
    if (phoneRef.current) return; // prevent reinitialization

    const phone = new AircallPhone({
      domToLoadPhone: "#phone",
      onLogin: (settings: any) => {
        setIsPhoneReady(true);
        handleCheckLogin();
        setStatusMessage("AirCall ready!");
        if (!settings?.user) {
          setIsPhoneReady(false);
          setStatusMessage("Login to Aircall");
        }
      },
      onLogout: () => {
        setStatusMessage("Aircall logged out");
        setIsPhoneReady(false);
      },
      debug: false,
    });

    phoneRef.current = phone;

    const handleIncomingCall = (callInfo: any) => {
      setShowAirCallPhone(true);

      setTimeout(() => {
        const message = `Incoming call from ${callInfo.from} to ${callInfo.to} ringing!`;
        addCallLog("incoming_call", callInfo, message);
        document.title = "🚨 " + document.title;
      }, 100);
    };

    phone.on("incoming_call", handleIncomingCall);

    phone.on("call_end_ringtone", (callInfo: any) => {
      const message = `Ringing ended. Call was ${callInfo.answer_status}`;
      document.title = document.title.replace("🚨 ", "");

      addCallLog("call_end_ringtone", callInfo, message);
    });

    phone.on("call_ended", (callInfo: any) => {
      const message = `Call ended. Lasted ${callInfo.duration} seconds`;
      addCallLog("call_ended", callInfo, message);
    });

    phone.on("comment_saved", (callInfo: any) => {
      const message = `Comment about the last call saved`;
      addCallLog("comment_saved", callInfo, message);
    });
  }, [handleCheckLogin, addCallLog]);

  useEffect(() => {
    // If there are active calls, ensure phone is visible
    const hasActiveCalls = callLogs.some(
      (log) =>
        log.id === "incoming_call" &&
        !callLogs.find(
          (endLog) =>
            endLog.id === "call_ended" &&
            endLog.payload.call_id === log.payload.call_id
        )
    );

    if (hasActiveCalls && !showAirCallPhone) {
      setShowAirCallPhone(true);
    }
  }, [callLogs, showAirCallPhone]);

  const safeSetShowAirCallPhone = useCallback(
    (show: boolean) => {
      // If trying to hide phone, check for active calls
      if (!show) {
        const hasActiveCalls = callLogs.some(
          (log) =>
            log.id === "incoming_call" &&
            !callLogs.find(
              (endLog) =>
                endLog.id === "call_ended" &&
                endLog.payload.call_id === log.payload.call_id
            )
        );

        if (hasActiveCalls) {
          return; // Don't hide if there are active calls
        }
      }

      setShowAirCallPhone(show);
    },
    [callLogs]
  );

  return (
    <PhoneContext.Provider
      value={{
        showAirCallPhone,
        setShowAirCallPhone: safeSetShowAirCallPhone,
        phoneRef,
        isPhoneReady,
        setIsPhoneReady,
        statusMessage,
        setStatusMessage,
        callLogs,
        addCallLog,
        handleCheckLogin,
        initializeAircall,
        isLoggedIn,
        airCallTab,
        setAirCallTab,
      }}
    >
      {children}
    </PhoneContext.Provider>
  );
};

export const usePhone = () => useContext(PhoneContext);
