import * as React from "react";
import { Show, ShowProps, useRecordContext } from "react-admin";
import { ClaimHeaderBar } from "../Components/ClaimHeaderBar";
import { ClaimTabs } from "../Components/tabs/claimShow/ClaimTabs";
import { useClaim } from "../context/hook/useClaim";
import { useClaimShowLogic } from "../context/hook/useClaimShowLogic";

export const ClaimShow = (props: ShowProps): React.ReactElement => {
  const { aside } = useClaimShowLogic(props.id);

  const handleKeyPress = React.useCallback(
    (event) => {
      if (event.target === document.body && event.key === "e") {
        window.open("/#/Claim/" + props?.id + "/edit", "_self");
      }

      if (event.target === document.body && event.key === "x") {
        window.open("/#/Claim/" + props?.id + "/edit", "_self");
      }
    },
    [props?.id]
  );

  React.useEffect(() => {
    // Attach the event listener
    document.addEventListener("keydown", handleKeyPress);

    // Remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <Show {...props} title="Claim" hasEdit={false} aside={aside}>
      <ClaimShowContent />
    </Show>
  );
};

const ClaimShowContent = () => {
  const recordContext = useRecordContext();

  const {
    record,
    debtorData,
    absoluteTotal,
    handleCallDebtor,
    hidePhone,
    userCountry,
    hasLawyers,
    hasInsolvencyAdministrators,
    statusToShow,
    notify,
    setUserCountry,
  } = useClaim(recordContext?.id);

  return (
    <>
      <ClaimHeaderBar
        absoluteTotal={absoluteTotal}
        userCountry={userCountry}
        setUserCountry={setUserCountry}
        record={record}
        debtorData={debtorData}
        hidePhone={hidePhone}
        handleCallDebtor={handleCallDebtor}
        hasLawyers={hasLawyers}
        hasInsolvencyAdministrators={hasInsolvencyAdministrators}
        statusToShow={statusToShow}
        notify={notify}
      />
      <ClaimTabs
        absoluteTotal={absoluteTotal}
        userCountry={userCountry}
        setUserCountry={setUserCountry}
        record={record}
        debtorData={debtorData}
        hidePhone={hidePhone}
        handleCallDebtor={handleCallDebtor}
      />
    </>
  );
};
