import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Record,
  useDataProvider,
  useGetIdentity,
  useGetList,
  useGetOne,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { Activity } from "../../api/activity/Activity";
import { normalizePhoneNumber } from "../../api/address/Address";
import { Payment } from "../../api/payment/Payment";
import { PaymentPlan } from "../../api/paymentPlan/PaymentPlan";
import { aircallService } from "../../util/AirCallCalls";
import { getStatusToShow } from "../../util/GetStatus";
import { usePhone } from "../PhoneContext";
import { useClaimShowLogic } from "./useClaimShowLogic";
import { useTitle } from "./useTitle";

export const useClaim = (claimId) => {
  const record: Record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const { airCallTab } = usePhone();

  const { absoluteTotal } = useClaimShowLogic(claimId);

  const [hasLawyers, setHasLawyers] = useState(false);
  const [hasInsolvencyAdministrators, setHasInsolvencyAdministrators] =
    useState(false);
  const [userCountry, setUserCountry] = useState();
  const [paymentPlans, setPaymentPlans] = useState<PaymentPlan[]>([]);

  const { data: creditorData } = useGetOne("User", record?.creditor.id || 0, {
    enabled: !!record?.creditor.id,
  });

  const { data: debtorData } = useGetOne("User", record?.debtor.id || 0, {
    enabled: !!record?.debtor.id,
  });

  const fetchPaymentPlans = useCallback(async () => {
    if (record?.paymentPlans && record.paymentPlans.length > 0) {
      const { data } = await dataProvider.getMany("PaymentPlan", {
        ids: record.paymentPlans.map((paymentPlan: any) => paymentPlan.id),
      });
      setPaymentPlans(data as PaymentPlan[]);
    } else {
      setPaymentPlans([]);
    }
  }, [dataProvider, record?.paymentPlans]);

  const { data: paymentData } = useGetList<Payment>(
    "Payment",
    { page: 1, perPage: 25 },
    { field: "paymentDate", order: "DESC" },
    { claim: { id: record?.id || 0 } },
    { enabled: !!record?.id }
  );

  const { data: activityData } = useGetList<Activity>(
    "Activity",
    { page: 1, perPage: 25 },
    { field: "activityDate", order: "DESC" },
    { claim: { id: record?.id || 0 } },
    { enabled: !!record?.id }
  );

  useEffect(() => {
    fetchPaymentPlans();

    return () => {
      setHasLawyers(false);
      setHasInsolvencyAdministrators(false);
    };
  }, [fetchPaymentPlans]);

  useEffect(() => {
    const checkAssociatedEntities = async () => {
      if (record?.id) {
        try {
          const { data: lawyers } = await dataProvider.getList("User", {
            filter: {
              claimsAsContact: { some: { id: { equals: record.id } } },
              userType: "Lawyer",
            },
            pagination: { page: 1, perPage: 1 },
            sort: { field: "id", order: "ASC" },
          });
          setHasLawyers(lawyers.length > 0);

          const { data: administrators } = await dataProvider.getList("User", {
            filter: {
              claimsAsContact: { some: { id: { equals: record.id } } },
              userType: "InsolvencyAdministrator",
            },
            pagination: { page: 1, perPage: 1 },
            sort: { field: "id", order: "ASC" },
          });
          setHasInsolvencyAdministrators(administrators.length > 0);
        } catch (error) {
          console.error("Error checking associated entities:", error);
        }
      }
    };

    checkAssociatedEntities();
  }, [dataProvider, record?.id]);

  const { identity } = useGetIdentity();
  const handleCallDebtor = useCallback(() => {
    let debtorPhone = debtorData?.phone;
    if (!debtorPhone) {
      notify("No phone number available for this debtor", "warning");
      return;
    }
    debtorPhone = normalizePhoneNumber(debtorPhone, userCountry, true);

    aircallService
      .dialNumber(identity?.username, identity?.id, debtorPhone)
      .then((result) => {
        if (result?.json) {
          notify("Dialing Call", "success");
          if (airCallTab && !airCallTab.closed) {
            // The tab is open, switch to it
            airCallTab.focus();
          } else {
            /*
            // The tab is closed or hasn't been opened, open it
            setAirCallTab(window.open("https://phone.aircall.io/", "_blank"));
            */
          }
        } else {
          notify("Could not dial :( Wrong number? Busy?", "error");
        }
      });
  }, [
    debtorData?.phone,
    userCountry,
    identity?.username,
    identity?.id,
    notify,
    airCallTab,
  ]);

  const statusToShow = useMemo(() => {
    return getStatusToShow(
      record,
      Object.values(activityData || {}) as Activity[],
      paymentPlans || [],
      Object.values(paymentData || {}) as Payment[]
    );
  }, [record, activityData, paymentPlans, paymentData]);

  const updateClaimStatus = useCallback(
    async (newStatus: string) => {
      if (
        ["Cancelled", "Paid", "Closed"].includes(newStatus) &&
        !window.confirm("Are you sure?")
      ) {
        return;
      }

      const newRecord = { ...record, status: newStatus };
      await dataProvider.update("Claim", {
        id: record.id,
        data: newRecord,
        previousData: record,
      });
      refresh();
    },
    [dataProvider, record, refresh]
  );

  useTitle(
    record
      ? `${record.reference} ${
          creditorData?.businessName
            ? ` ${creditorData.businessName.slice(0, 3).toUpperCase()} `
            : ""
        } ./. ${
          debtorData?.businessName ||
          debtorData?.contactName ||
          debtorData?.name
        }`
      : ""
  );

  const hidePhone = !debtorData || !debtorData.phone;

  return {
    record,
    creditorData,
    debtorData,
    hasLawyers,
    hasInsolvencyAdministrators,
    userCountry,
    setUserCountry,
    absoluteTotal,
    paymentPlans,
    paymentData,
    activityData,
    statusToShow,
    handleCallDebtor,
    updateClaimStatus,
    notify,
    refresh,
    hidePhone,
  };
};
