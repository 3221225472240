import { Badge } from "@material-ui/core";
import * as icons from "@material-ui/icons";
import React, { useState } from "react";
import { Button, Tab, useGetIdentity } from "react-admin";
import { VIP_USERS } from "../../../config/AppConfig";
import { theme } from "../../../theme/theme";
import { ClaimDrawer } from "../../ClaimDrawer";
import { TabbedShowLayoutStyled } from "./TabbedShowLayoutStyled";
import { ActivitiesTab } from "./tabs/ActivitiesTab";
import { CommentTab } from "./tabs/CommentTab";
import { FilesTab } from "./tabs/FilesTab";
import { GeneralTab } from "./tabs/GeneralTab";
import { PaymentsTab } from "./tabs/PaymentsTab";

export const ClaimTabs = React.memo((props: any) => {
  const {
    AppsOutlined,
    PaymentOutlined,
    ChatOutlined,
    FileCopyOutlined,
    CommentOutlined,
    CallOutlined,
  } = icons;

  const [showActivityAdd, setShowActivityAdd] = useState(false);
  const [activityPlaceholderText, setActivityPlaceholderText] = useState("");
  const { identity } = useGetIdentity();

  return (
    <>
      <TabbedShowLayoutStyled>
        <Tab label="General" icon={<AppsOutlined />}>
          <GeneralTab
            userCountry={props.userCountry}
            setUserCountry={props.setUserCountry}
          />
        </Tab>
        <Tab label="Payments" icon={<PaymentOutlined />}>
          <PaymentsTab absoluteTotal={props.absoluteTotal} />
        </Tab>
        <Tab label="Activities" icon={<ChatOutlined />}>
          <ActivitiesTab
            showActivityAdd={showActivityAdd}
            setShowActivityAdd={setShowActivityAdd}
            activityPlaceholderText={activityPlaceholderText}
            setActivityPlaceholderText={setActivityPlaceholderText}
          />
        </Tab>
        {(identity?.username?.includes("debtist.de") ||
          identity?.username === "admin") && (
          <Tab label="Files" icon={<FileCopyOutlined />}>
            <FilesTab />
          </Tab>
        )}
        <Tab
          label="Comment"
          icon={
            <Badge
              invisible={!props.record?.comment?.length}
              badgeContent={<></>}
              color={"primary"}
              variant="dot"
              style={{ marginTop: 4 }}
            >
              <CommentOutlined />
            </Badge>
          }
          style={{
            background: props.record?.comment?.length ? "var(--primary)" : "",
            color: props.record?.comment?.length ? "#fff" : "",
          }}
        >
          <CommentTab />
        </Tab>

        {!props.hidePhone && (
          <Button
            hidden={props.hidePhone}
            title="Call Debtor"
            onClick={props.handleCallDebtor}
            style={{
              position: "absolute",
              right: 25,
              top: "50%",
              padding: 8,
              transform: "translateY(-50%)",
            }}
            size="small"
          >
            <>
              <CallOutlined />
              {(!props.debtorData.phoneVerificationStatus ||
                props.debtorData.phoneVerificationStatus === "UNKNOWN") && (
                <icons.Help
                  style={{
                    height: 14,
                    color: theme.palette.warning.dark,
                  }}
                />
              )}
              {props.debtorData.phoneVerificationStatus === "VALID" && (
                <icons.VerifiedUser
                  style={{
                    height: 14,
                    color: theme.palette.success.main,
                  }}
                />
              )}
              {props.debtorData.phoneVerificationStatus === "INVALID" && (
                <icons.RemoveCircle
                  style={{
                    height: 14,
                    color: theme.palette.error.main,
                  }}
                />
              )}
            </>
          </Button>
        )}
      </TabbedShowLayoutStyled>
      {VIP_USERS.includes(props.record?.identity?.username) && <ClaimDrawer />}
    </>
  );
});
