import {
  Box,
  colors,
  Grid,
  IconButton,
  List,
  ListItem,
  Modal,
  Tooltip,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import {
  AutocompleteInput,
  BooleanInput,
  DateInput,
  DeleteWithConfirmButton,
  Edit,
  EditProps,
  FunctionField,
  NumberInput,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useGetIdentity,
  useGetOne,
  useNotify,
  useRefresh,
} from "react-admin";
import { useForm, useFormState } from "react-final-form";

import currency from "currency.js";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { CLAIM_TYPES } from "../api/claim/Claim";
import AssignClaimForm from "../Components/fields/AssignClaimForm";
import { useSocket } from "../context/hook/useSocket";
import {
  EURO_CURRENCY_FORMAT,
  SIMPLE_EURO_CURRENCY_FORMAT,
} from "../payment/PaymentCreate";
import { theme } from "../theme/theme";
import { UserTitle } from "../user/UserTitle";
import { getInitials } from "../util/StyleUtils";
import { getFormattedClaimStatusChoices } from "./ClaimCreate";

const OriginalAmountDueInput = () => {
  const { values } = useFormState();
  const form = useForm();

  return (
    <NumberInput
      required
      label="Original Amount Due (Gross)"
      source="originalAmountDue"
      onPaste={async (e) => {
        e.preventDefault();
        let pasteContent = e.clipboardData.getData("text");
        if (pasteContent) {
          const parsedInput =
            EURO_CURRENCY_FORMAT.test(pasteContent) ||
            SIMPLE_EURO_CURRENCY_FORMAT.test(pasteContent)
              ? currency(pasteContent.replace(".", "").replace(",", "."), {
                  separator: "",
                  decimal: ".",
                  symbol: "€",
                }).value
              : currency(pasteContent, {
                  separator: ",",
                  decimal: ".",
                  symbol: "$",
                }).value;

          if (!isNaN(parsedInput)) {
            form.change("originalAmountDue", parsedInput);
          }
        }
      }}
      onBlur={() => {
        if (!values.totalPending) {
          values.totalPending = values.originalAmountDue;
        }
      }}
    />
  );
};

const TotalPaidInput = () => {
  const form = useForm();

  return (
    <NumberInput
      label="Total Paid"
      source="totalPaid"
      onPaste={async (e) => {
        e.preventDefault();
        let pasteContent = e.clipboardData.getData("text");
        if (pasteContent) {
          const parsedInput =
            EURO_CURRENCY_FORMAT.test(pasteContent) ||
            SIMPLE_EURO_CURRENCY_FORMAT.test(pasteContent)
              ? currency(pasteContent.replace(".", "").replace(",", "."), {
                  separator: "",
                  decimal: ".",
                  symbol: "€",
                }).value
              : currency(pasteContent, {
                  separator: ",",
                  decimal: ".",
                  symbol: "$",
                }).value;

          if (!isNaN(parsedInput)) {
            form.change("totalPaid", parsedInput);
          }
        }
      }}
    />
  );
};

const TotalPendingInput = () => {
  const { values } = useFormState();
  const form = useForm();

  return (
    <NumberInput
      required
      label="Total Pending"
      source="totalPending"
      onBlur={() => {
        if (!values.originalAmountDue) {
          values.originalAmountDue = values.totalPending;
        }
      }}
      onPaste={async (e) => {
        e.preventDefault();
        let pasteContent = e.clipboardData.getData("text");
        if (pasteContent) {
          const parsedInput =
            EURO_CURRENCY_FORMAT.test(pasteContent) ||
            SIMPLE_EURO_CURRENCY_FORMAT.test(pasteContent)
              ? currency(pasteContent.replace(".", "").replace(",", "."), {
                  separator: "",
                  decimal: ".",
                  symbol: "€",
                }).value
              : currency(pasteContent, {
                  separator: ",",
                  decimal: ".",
                  symbol: "$",
                }).value;

          if (!isNaN(parsedInput)) {
            form.change("totalPending", parsedInput);
          }
        }
      }}
    />
  );
};

const StageInput = (props: any) => {
  const [manualMode, setManualMode] = useState(false);
  const { values } = useFormState();

  if (!manualMode) {
    if (values.email) {
      values.username = values.email;
    } else {
      values.username = values.phone;
    }
  }

  return (
    <SelectInput
      source="stage"
      label="Stage"
      disabled={!manualMode}
      onDoubleClick={() => setManualMode(true)}
      onBlur={(event) => {
        if (!event?.target?.value) {
          setManualMode(false);
        }
      }}
      choices={[
        { label: "Reminder", value: "Reminder" },
        { label: "Precourt", value: "Precourt" },
        { label: "Court", value: "Court" },
        { label: "Monitoring", value: "Monitoring" },
      ]}
      optionText="label"
      optionValue="value"
    />
  );
};

const StatusInput = (props: any) => {
  const form = useForm();
  const { values } = useFormState();

  // hacky workaround: assigne this somewhere inside the claim form
  if (values.assignee?.id || (props.assigneeId && !values.assignee?.id)) {
    props.setAssigneeId(values.assignee?.id);
  }

  return (
    <FunctionField
      label="Status"
      source="status"
      style={{ fontWeight: 600 }}
      render={(record: any) => {
        const status = values?.status;
        return (
          <div
            style={{
              fontWeight: 600,
              background: ["Paid", "PaidOut"].includes(status)
                ? theme.palette.success.dark
                : status === "Paused"
                ? theme.palette.grey[800]
                : status === "Open"
                ? theme.palette.primary.light
                : status === "JudicialLevel"
                ? theme.palette.error.dark
                : status === "EncashmentLevel"
                ? theme.palette.secondary.main
                : status === "ReminderLevel"
                ? theme.palette.info.main
                : ["Closed", "Cancelled"].includes(status)
                ? theme.palette.error.main
                : theme.palette.grey[800],
              color: "white",
              padding: 13,
              fontSize: 16,
              borderRadius: 8,
              paddingLeft: 10,
              paddingRight: 10,
              marginTop: 8,
              position: "relative",
            }}
          >
            {values?.status}
            <SelectInput
              style={{
                position: "absolute",
                right: 0,
                top: 0,
                opacity: 0,
              }}
              source="status"
              label="Status"
              onChange={(e) => {
                form.change("status", e.target.value);
              }}
              choices={getFormattedClaimStatusChoices()}
              optionText="label"
              optionValue="value"
            />
          </div>
        );
      }}
    />
  );
};

const AssigneeModal = (props: any) => {
  const refresh = useRefresh();
  const [showAssigneeList, setShowAssigneeList] = useState(false);
  const handleCloseAssigneeModal = useCallback(() => {
    setShowAssigneeList((prev) => !prev);
  }, []);

  const { data: operator } = useGetOne("User", props.assigneeId, {
    enabled: !!props.assigneeId,
  });

  return (
    <>
      <Modal open={showAssigneeList} onClose={handleCloseAssigneeModal}>
        <Box className="modal-box">
          <List>
            <ListItem>
              <Typography variant={"h5"} align={"left"}>
                {"Assign User to Claim"}
              </Typography>
            </ListItem>
            <AssignClaimForm
              claimId={props.id}
              assigneeId={props.assigneeId}
              setShow={setShowAssigneeList}
              refresh={() => refresh()}
            />
          </List>
        </Box>
      </Modal>
      <Grid container spacing={2} style={{ width: "100%" }}>
        <Grid item xs={9}></Grid>
        <Grid item xs={3} style={{ position: "relative" }}>
          {operator && (
            <Tooltip
              title={operator?.contactName || ""}
              style={{
                position: "absolute",
                marginTop: 3,
                left: -50,
              }}
            >
              <div className="assigned-user">
                {getInitials(
                  operator?.contactName ? operator.contactName : null
                )}
              </div>
            </Tooltip>
          )}

          <Tooltip
            title={`${props.assigneeId ? "Change" : "Assign"} Claim Assignee`}
          >
            <IconButton
              onClick={handleCloseAssigneeModal}
              style={{
                display: "block",
                width: "100%",
                borderRadius: 8,
                textAlign: "right",
              }}
            >
              <Typography variant={"button"} align={"right"}>
                {`${props.assigneeId ? "Change" : "Assign"} Claim Assignee`}
              </Typography>
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </>
  );
};

export const ClaimEdit = (props: EditProps): React.ReactElement => {
  const history = useHistory();
  const notify = useNotify();
  const { identity } = useGetIdentity();
  const { socket, isConnected } = useSocket();
  const [assigneeId, setAssigneeId] = useState();

  React.useEffect(() => {
    if (isConnected && props.id && identity?.id) {
      socket.emit("userJoinsClaim", {
        claimId: props.id,
        userId: identity.id,
      });

      return () => {
        socket.emit("userLeavesClaim", {
          claimId: props.id,
          userId: identity.id,
        });
      };
    }
  }, [isConnected, socket, identity?.id, props.id]);

  useEffect(() => {
    setTimeout(() => {
      const hash = window.location.hash.substring(1).split("#")[1];
      if (hash) {
        document.getElementById(hash)?.scrollIntoView();
      }
    }, 500);
  }, []);

  const handleKeyPress = useCallback(
    (event) => {
      if (event.target === document.body && event.key === "e") {
        window.open("/#/Claim/" + props.id + "/show", "_self");
      }
    },
    [props.id]
  );

  useEffect(() => {
    // attach the event listener
    document.addEventListener("keydown", handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <>
      <AssigneeModal id={props.id} assigneeId={assigneeId} />
      <Edit
        {...props}
        onSuccess={() => {
          notify("Success", "info", null, true);
          history.goBack();
        }}
        mutationMode={"pessimistic"}
        actions={false}
      >
        <SimpleForm
          toolbar={
            <Toolbar className="elevated-card">
              <DeleteWithConfirmButton
                undoable={false}
                mutationMode="pessimistic"
                label="Delete"
                variant="outlined"
                style={{
                  borderColor: colors.red[400],
                  padding: "6px 16px",
                }}
              />
              <SaveButton label="Save" />
            </Toolbar>
          }
        >
          <Grid
            container
            spacing={2}
            style={{ width: "100%", marginBottom: 20 }}
          >
            <Grid item xs={4}></Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={6}>
              <ReferenceInput
                filterToQuery={(searchText) => ({
                  name: { contains: searchText },
                })}
                source="creditor.id"
                reference="User"
                label="Creditor"
                style={{ width: "100%" }}
              >
                <AutocompleteInput suggestionLimit={5} optionText={UserTitle} />
              </ReferenceInput>
            </Grid>
            <Grid item xs={6}>
              <ReferenceInput
                filterToQuery={(searchText) => ({
                  name: { contains: searchText },
                })}
                source="debtor.id"
                reference="User"
                label="Debtor"
              >
                <AutocompleteInput suggestionLimit={5} optionText={UserTitle} />
              </ReferenceInput>
            </Grid>

            <Grid item xs={6}>
              <DateInput label="Invoice Created At" source="invoiceCreatedAt" />
            </Grid>
            <Grid item xs={6}>
              <DateInput label="Due Date" source="dueDate" />
            </Grid>
            <Grid item xs={6}>
              <StatusInput
                assigneeId={assigneeId}
                setAssigneeId={setAssigneeId}
              />
            </Grid>
            <Grid item xs={6}>
              <StageInput />
            </Grid>
            <Grid item xs={6}>
              <SelectInput
                source="claimType"
                label="Claim Type"
                choices={CLAIM_TYPES.map((claim) => ({
                  label: claim.title + " (" + claim.number + ")",
                  value: claim.key,
                }))}
                optionText="label"
                allowEmpty
                optionValue="value"
              />
            </Grid>

            <Grid item xs={6}>
              <TextInput
                label="Title (External ID)"
                source="title"
                multiline
                fullWidth
                minRows={1}
              />
            </Grid>
            <Grid item xs={6}>
              <OriginalAmountDueInput />
            </Grid>
            <Grid item xs={6}>
              <SelectInput
                source="currency"
                label="Currency"
                defaultValue={"EUR"}
                choices={[
                  { label: "EUR", value: "EUR" },
                  { label: "USD", value: "USD" },
                  { label: "GBP", value: "GBP" },
                  { label: "CHF", value: "CHF" },
                  { label: "PLN", value: "PLN" },
                  { label: "DKK", value: "DKK" },
                  { label: "NOK", value: "NOK" },
                  { label: "SEK", value: "SEK" },
                ]}
                optionText="label"
                optionValue="value"
              />
            </Grid>
            <Grid item xs={6}>
              <TotalPaidInput />
            </Grid>
            <Grid item xs={6}>
              <TotalPendingInput />
            </Grid>
            <Grid item xs={6}>
              <NumberInput required label="Tax Rate" source="taxRate" />
            </Grid>
            <Grid item xs={6}>
              <NumberInput label="Interest Rate" source="interestRate" />
              <NumberInput
                label="Auto Settlement in Percent"
                source="autoSettlementInPercent"
              />
              <DateInput
                required
                label="Auto Settlement Expiry"
                source="autoSettlementExpiry"
              />
            </Grid>

            <Grid item xs={12}>
              <BooleanInput
                defaultValue={false}
                label="Creditor Feedback Required"
                source="creditorFeedbackRequired"
                style={{ display: "inline" }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextInput
                label="Comment"
                multiline
                minRows={6}
                fullWidth
                source="comment"
              />
            </Grid>

            <Grid item xs={12}>
              <TextInput label="File URLs" source="fileUrls" fullWidth />
            </Grid>

            <Grid item xs={6}>
              <TextInput label="External ID" source="externalId" />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                label="Contacts"
                multiline
                minRows={1}
                fullWidth
                source="contacts"
              />
            </Grid>
          </Grid>
        </SimpleForm>
      </Edit>
    </>
  );
};
