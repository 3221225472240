import {
  Divider,
  Grid,
  ListItem,
  ListItemText,
  List as MuiList,
  Typography,
} from "@material-ui/core";
import * as icons from "@material-ui/icons";
import { Add, CloseRounded } from "@material-ui/icons";
import React from "react";
import {
  BooleanField,
  Button,
  Datagrid,
  DateField,
  FunctionField,
  List,
  ReferenceField,
  TextField,
  TopToolbar,
  useDataProvider,
  useRecordContext,
  useRefresh,
} from "react-admin";
import ReactCountryFlag from "react-country-flag";
import {
  ADDRESS_COUNTRY_READABLE,
  ADDRESS_COUNTRY_SHORT,
} from "../../../../api/address/Address";
import { EnumAddressCountry } from "../../../../api/address/EnumAddressCountry";
import { getReadableUserType } from "../../../../api/user/User";
import { theme } from "../../../../theme/theme";
import { UserBusinessName, UserEmail } from "../../../../user/UserShow";
import { searchMapByKey } from "../../../../util/MapUtils";
import { PhoneNumberComponent } from "../../../ClaimPhoneNumbers";

const Contacts = ({
  hasLawyers,
  setHasLawyers,
  hasInsolvencyAdministrators,
  setHasInsolvencyAdministrators,
  userCountry,
}) => {
  const claim = useRecordContext();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  if (!claim) {
    return null;
  }

  return (
    <>
      <Typography variant="h6">
        <icons.PersonAddOutlined style={{ verticalAlign: "sub" }} /> Contacts
      </Typography>
      <TopToolbar className="align-btns">
        <Button
          href={
            "/#/User/create?claimId=" +
            claim?.id +
            "&userType=AdditionalContact"
          }
          startIcon={<Add />}
          style={{ backgroundColor: theme.palette.success.dark }}
          label="Contact"
        />
        <Button
          href={"/#/User/create?claimId=" + claim?.id + "&userType=Lawyer"}
          startIcon={<Add />}
          style={{ backgroundColor: theme.palette.error.dark }}
          label="Lawyer"
        />
        <Button
          href={
            "/#/User/create?claimId=" +
            claim?.id +
            "&userType=InsolvencyAdministrator"
          }
          startIcon={<Add />}
          style={{ backgroundColor: theme.palette.warning.dark }}
          label="Insolvency manager"
        />
      </TopToolbar>
      {claim?.id && (
        <List
          resource="User"
          basePath="/User"
          exporter={false}
          title={" "}
          actions={false}
          filter={{
            claimsAsContact: { some: { id: { equals: claim?.id } } },
          }}
          filterDefaultValues={{
            claimsAsContact: { some: { id: { equals: claim?.id } } },
          }}
          perPage={50}
          pagination={null}
          sort={{ field: "userType", order: "DESC" }}
        >
          <Datagrid
            optimized
            size="medium"
            isRowSelectable={() => false}
            hasBulkActions={false}
            rowClick=""
          >
            <ReferenceField
              link={false}
              label="Contacts"
              source="id"
              reference="User"
              emptyText="-"
              sortBy="userType"
            >
              <FunctionField
                label="Contact"
                render={(record: any) => {
                  if (
                    record?.userType === "Lawyer" &&
                    !hasLawyers &&
                    claim?.contacts &&
                    claim.contacts.some((contact) => contact?.id === record?.id)
                  ) {
                    setHasLawyers(true);
                  }
                  if (
                    record?.userType === "InsolvencyAdministrator" &&
                    !hasInsolvencyAdministrators &&
                    claim?.contacts &&
                    claim.contacts.some((contact) => contact?.id === record?.id)
                  ) {
                    setHasInsolvencyAdministrators(true);
                  }

                  return (
                    <Grid container>
                      <Grid xs={2} item>
                        <a
                          style={{
                            textDecoration: "none",
                            fontWeight: 600,
                            color: theme.palette.grey[700],
                          }}
                          href={`/#/User/${record?.id}/show`}
                        >
                          {record?.userType &&
                            record?.userType !== "Default" && (
                              <span
                                style={{
                                  color:
                                    record?.userType !== "AdditionalContact"
                                      ? theme.palette.error.main
                                      : theme.palette.warning.dark,
                                }}
                              >
                                {getReadableUserType(record?.userType)}
                                {record?.userType === "AdditionalContact" && (
                                  <span
                                    title="Kontakt"
                                    style={{
                                      background: theme.palette.warning.dark,
                                      color: "white",
                                      padding: "8px 10px",
                                      marginLeft: 5,
                                      fontSize: 12,
                                      fontWeight: "bold",
                                      borderRadius: 8,
                                    }}
                                  >
                                    <icons.PersonAdd
                                      style={{
                                        height: 12,
                                        width: 12,
                                        position: "relative",
                                        top: 2,
                                      }}
                                    />
                                  </span>
                                )}
                                {record?.userType === "Lawyer" && (
                                  <span
                                    title="Anwalt"
                                    style={{
                                      background: theme.palette.error.dark,
                                      color: "white",
                                      padding: 5,
                                      marginLeft: 5,
                                      fontSize: 12,
                                      fontWeight: "bold",
                                      borderRadius: 8,
                                    }}
                                  >
                                    <icons.Gavel
                                      style={{
                                        height: 12,
                                        width: 12,
                                        position: "relative",
                                        top: 2,
                                      }}
                                    />
                                  </span>
                                )}
                                {record?.userType ===
                                  "InsolvencyAdministrator" && (
                                  <span
                                    title="Insolvenzverwalter"
                                    style={{
                                      background: theme.palette.error.dark,
                                      color: "white",
                                      padding: 5,
                                      marginLeft: 3,
                                      fontSize: 12,
                                      fontWeight: "bold",
                                      borderRadius: 8,
                                    }}
                                  >
                                    <icons.MoneyOff
                                      style={{
                                        height: 12,
                                        width: 12,
                                        position: "relative",
                                        top: 2,
                                      }}
                                    />
                                  </span>
                                )}
                              </span>
                            )}
                        </a>
                      </Grid>
                      <Grid xs={9} item>
                        <a
                          style={{
                            textDecoration: "none",
                            color: theme.palette.grey[700],
                          }}
                          href={`/#/User/${record?.id}/show`}
                        >
                          <span
                            style={{
                              color: theme.palette.primary.main,
                            }}
                          >
                            {record?.name}
                          </span>
                        </a>
                        {!!record?.name ? " | " : ""} <UserEmail />
                        {!!record?.email && !!record?.phone ? " | " : ""}{" "}
                        <PhoneNumberComponent country={userCountry} />
                      </Grid>
                      <Grid xs={1} item style={{ position: "relative" }}>
                        <CloseRounded
                          style={{
                            height: 16,
                            cursor: "pointer",
                            color: theme.palette.error.main,
                            padding: 15,
                            verticalAlign: "middle",
                            position: "absolute",
                            right: 0,
                            top: -15,
                            zIndex: 3,
                          }}
                          onClick={async (e) => {
                            e.stopPropagation();
                            if (
                              window.confirm(
                                "Do you really want to remove this contact?"
                              )
                            ) {
                              const newContacts = [];
                              claim?.contacts
                                .filter((contact) => contact?.id !== record?.id)
                                .forEach((contact) => {
                                  newContacts.push({ id: contact.id });
                                });
                              // todo: make it work
                              await dataProvider.update("Claim", {
                                id: claim.id,
                                data: {
                                  contacts: newContacts.length
                                    ? {
                                        set: newContacts,
                                      }
                                    : null,
                                },
                                previousData: record,
                              });
                              refresh();
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                  );
                }}
              />
            </ReferenceField>
          </Datagrid>
        </List>
      )}
    </>
  );
};

export const GeneralTab: React.FC<any> = ({
  userCountry,
  setUserCountry,
}: any) => {
  const [emailTitle, setEmailTitle] = React.useState<string>("");

  return (
    <>
      <Grid
        container
        spacing={2}
        style={{
          width: "100%",
          paddingTop: 15,
          marginBottom: 30,
          position: "relative",
        }}
      >
        <Grid item xs={12} lg={6}>
          <Typography variant="h6">Claim:</Typography>
          <MuiList>
            <ListItem alignItems="flex-start">
              <ListItemText
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                primary={<Typography variant="caption">Title:</Typography>}
                secondary={
                  <TextField
                    label="Title (External Identifier)"
                    source="title"
                    style={{ fontWeight: 600 }}
                  />
                }
              />
            </ListItem>
            <Divider component="li" />
            <ListItem alignItems="flex-start">
              <ListItemText
                primary={<Typography variant="caption">Status:</Typography>}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                secondary={
                  <FunctionField
                    label="Status"
                    source="status"
                    render={(record: any) => {
                      const stage = record?.stage;
                      return (
                        <>
                          <span
                            style={{
                              fontWeight: 600,
                              color: !stage
                                ? theme.palette.grey[800]
                                : stage === "Reminder"
                                ? theme.palette.info.main
                                : stage === "Precourt"
                                ? theme.palette.secondary.main
                                : stage === "Court"
                                ? theme.palette.error.dark
                                : stage === "Monitoring"
                                ? theme.palette.info.dark
                                : theme.palette.grey[800],
                              fontSize: 12,
                              borderRadius: 8,
                              paddingRight: 10,
                              marginRight: 10,
                            }}
                          >
                            {stage === "Precourt" ? (
                              <icons.Mail
                                style={{
                                  height: 14,
                                  position: "relative",
                                  top: 3,
                                }}
                              />
                            ) : stage === "Court" ? (
                              <icons.Gavel
                                style={{
                                  height: 14,
                                  position: "relative",
                                  top: 3,
                                }}
                              />
                            ) : stage === "Monitoring" ? (
                              <icons.Visibility
                                style={{
                                  height: 14,
                                  position: "relative",
                                  top: 3,
                                }}
                              />
                            ) : stage === "Reminder" ? (
                              <icons.Notifications
                                style={{
                                  height: 14,
                                  position: "relative",
                                  top: 3,
                                }}
                              />
                            ) : (
                              <></>
                            )}
                            {stage}
                          </span>
                          <span
                            style={{
                              fontWeight: 600,
                              background: ["Paid", "PaidOut"].includes(
                                record?.status
                              )
                                ? theme.palette.success.dark
                                : record?.status === "Paused"
                                ? theme.palette.grey[800]
                                : record?.status === "Open"
                                ? theme.palette.primary.light
                                : record?.status === "JudicialLevel"
                                ? theme.palette.error.dark
                                : record?.status === "EncashmentLevel"
                                ? theme.palette.secondary.main
                                : record?.status === "ReminderLevel"
                                ? theme.palette.info.main
                                : ["Closed", "Cancelled"].includes(
                                    record?.status
                                  )
                                ? theme.palette.error.main
                                : theme.palette.grey[800],
                              color: "white",
                              padding: 7,
                              fontSize: 12,
                              maxWidth: 100,
                              borderRadius: 8,
                              paddingLeft: 10,
                              paddingRight: 10,
                            }}
                          >
                            {record?.status}
                          </span>
                        </>
                      );
                    }}
                  />
                }
              />
            </ListItem>
            <Divider component="li" />

            <ListItem alignItems={"flex-start"}>
              <ListItemText
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                primary={
                  <Typography variant="caption">
                    Creditor Feedback Required:
                  </Typography>
                }
                secondary={
                  <BooleanField
                    label="Creditor Feedback Required"
                    source="creditorFeedbackRequired"
                    emptyText="x"
                    color={"secondary"}
                  />
                }
              ></ListItemText>
            </ListItem>
            <Divider component="li" />

            <ListItem alignItems={"flex-start"}>
              <ListItemText
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                primary={<Typography variant="caption">Created At:</Typography>}
                secondary={
                  <DateField
                    source="createdAt"
                    label="Created At"
                    locales="de-DE"
                    options={{
                      month: "2-digit",
                      day: "2-digit",
                      year: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      timeZone: "Europe/Berlin",
                    }}
                    style={{ fontWeight: 600 }}
                  />
                }
              ></ListItemText>
            </ListItem>
            <Divider component="li" />

            <ListItem alignItems={"flex-start"}>
              <ListItemText
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                primary={<Typography variant="caption">Updated At:</Typography>}
                secondary={
                  <>
                    <ReferenceField
                      label="Manager"
                      source="manager.id"
                      reference="User"
                      link="show"
                    >
                      <FunctionField
                        label="Manager"
                        render={(record: any) => (
                          <span>
                            {record?.username?.split("@")?.[0]}
                            <Typography variant="caption" color="textPrimary">
                              :
                            </Typography>
                            &nbsp;
                          </span>
                        )}
                      />
                    </ReferenceField>
                    <DateField
                      source="updatedAt"
                      label="Updated At"
                      locales="de-DE"
                      options={{
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        timeZone: "Europe/Berlin",
                      }}
                      style={{ fontWeight: 600 }}
                    />
                  </>
                }
              ></ListItemText>
            </ListItem>
            <Divider component="li" />

            <ListItem alignItems={"flex-start"}>
              <ListItemText
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                primary={<Typography variant="caption">Claim type:</Typography>}
                secondary={
                  <TextField
                    label="Claim Type"
                    source="claimType"
                    emptyText="-"
                  />
                }
              ></ListItemText>
            </ListItem>
            <Divider component="li" />

            <ListItem alignItems={"flex-start"}>
              <ListItemText
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                primary={
                  <Typography variant="caption">Interest Rate:</Typography>
                }
                secondary={
                  <TextField
                    label="Interest Rate"
                    source="interestRate"
                    emptyText="-"
                  />
                }
              ></ListItemText>
            </ListItem>

            <Divider component="li" />

            <ListItem alignItems={"flex-start"}>
              <ListItemText
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                primary={
                  <Typography variant="caption">
                    Auto Settlement in Percent:
                  </Typography>
                }
                secondary={
                  <TextField
                    label="Auto Settlement in Percent"
                    source="autoSettlementInPercent"
                    emptyText="-"
                  />
                }
              ></ListItemText>
            </ListItem>

            <Divider component="li" />

            <ListItem alignItems={"flex-start"}>
              <ListItemText
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                primary={
                  <Typography variant="caption">
                    Auto Settlement expiry:
                  </Typography>
                }
                secondary={
                  <DateField
                    label="Auto Settlement Expiry"
                    source="autoSettlementExpiry"
                  />
                }
              ></ListItemText>
            </ListItem>

            <Divider component="li" />

            <ListItem alignItems={"flex-start"}>
              <ListItemText
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                primary={
                  <Typography variant="caption">External ID:</Typography>
                }
                secondary={
                  <TextField
                    label="External ID"
                    source="externalId"
                    emptyText="-"
                  />
                }
              ></ListItemText>
            </ListItem>

            <Divider component="li" />

            <ListItem alignItems={"flex-start"}>
              <ListItemText
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                primary={
                  <Typography variant="caption">
                    External File Number Own Lawyer:
                  </Typography>
                }
                secondary={
                  <TextField
                    label="External File Number Own Lawyer"
                    source="externalFileNumberOwnLawyer"
                    emptyText="-"
                  />
                }
              ></ListItemText>
            </ListItem>

            <Divider component="li" />

            <ListItem alignItems={"flex-start"}>
              <ListItemText
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                primary={
                  <Typography variant="caption">
                    External File Number Opposing Lawyer:
                  </Typography>
                }
                secondary={
                  <TextField
                    label="External File Number Opposing Lawyer"
                    source="externalFileNumberOpposingLawyer"
                    emptyText="-"
                  />
                }
              ></ListItemText>
            </ListItem>

            <Divider component="li" />

            <ListItem alignItems={"flex-start"}>
              <ListItemText
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                primary={
                  <Typography variant="caption">
                    External File Number Insolvency:
                  </Typography>
                }
                secondary={
                  <TextField
                    label="External File Number Insolvency"
                    source="externalFileNumberInsolvency"
                    emptyText="-"
                  />
                }
              ></ListItemText>
            </ListItem>

            <Divider component="li" />

            <ListItem alignItems={"flex-start"}>
              <ListItemText
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                primary={
                  <Typography variant="caption">
                    External File Number Court Order:
                  </Typography>
                }
                secondary={
                  <TextField
                    label="External File Number Court Order"
                    source="externalFileNumberCourtOrder"
                    emptyText="-"
                  />
                }
              ></ListItemText>
            </ListItem>

            <Divider component="li" />

            <ListItem alignItems={"flex-start"}>
              <ListItemText
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                primary={
                  <Typography variant="caption">
                    External File Number Court Verdict:
                  </Typography>
                }
                secondary={
                  <TextField
                    label="External File Number Court Verdict"
                    source="externalFileNumberCourtVerdict"
                    emptyText="-"
                  />
                }
              ></ListItemText>
            </ListItem>

            <Divider component="li" />

            <ListItem alignItems={"flex-start"}>
              <ListItemText
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                primary={<Typography variant="caption">Title Date:</Typography>}
                secondary={
                  <TextField
                    label="Title Date"
                    source="titleDate"
                    emptyText="-"
                  />
                }
              ></ListItemText>
            </ListItem>
          </MuiList>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Typography variant="h6">Debtor:</Typography>
          <MuiList>
            <ListItem alignItems="flex-start">
              <ListItemText
                primary={
                  <Typography variant="caption">Business Name:</Typography>
                }
                secondary={
                  <ReferenceField
                    link=""
                    label="Debtor"
                    source="debtor.id"
                    reference="User"
                  >
                    <FunctionField
                      label="Debtor"
                      render={(record: any) => <UserBusinessName />}
                    />
                  </ReferenceField>
                }
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              />
            </ListItem>
            <Divider component="li" />
            <ListItem alignItems="flex-start">
              <ListItemText
                primary={
                  <Typography variant="caption">Business Type:</Typography>
                }
                secondary={
                  <ReferenceField
                    link=""
                    label="Debtor"
                    source="debtor.id"
                    reference="User"
                  >
                    <FunctionField
                      label="Debtor"
                      render={(record: any) => (
                        <span
                          title="Business Type"
                          style={{
                            color:
                              record?.businessType === "Consumer"
                                ? "#995200"
                                : "#000099",
                            background:
                              record?.businessType === "Consumer"
                                ? "#ffc480"
                                : "#d1d1ff",
                            padding: "5px 10px",
                            marginRight: 8,
                            fontSize: 12,
                            fontWeight: "bold",
                            borderRadius: 8,
                          }}
                        >
                          {record?.businessType === "Consumer" ? "B2C" : "B2B"}
                        </span>
                      )}
                    />
                  </ReferenceField>
                }
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              />
            </ListItem>
            <Divider component="li" />

            <ListItem alignItems={"flex-start"}>
              <ListItemText
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                primary={
                  <Typography variant="caption">Contact Name:</Typography>
                }
                secondary={
                  <ReferenceField
                    link=""
                    label="Debtor"
                    source="debtor.id"
                    reference="User"
                  >
                    <FunctionField
                      label="Debtor"
                      render={(record: any) => {
                        return <span>{record?.contactName || "-"}</span>;
                      }}
                    />
                  </ReferenceField>
                }
              ></ListItemText>
            </ListItem>
            <Divider component="li" />

            <ListItem alignItems={"flex-start"}>
              <ListItemText
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                primary={
                  <Typography variant="caption">{`${emailTitle}:`}</Typography>
                }
                secondary={
                  <ReferenceField
                    link=""
                    label="Debtor"
                    source="debtor.id"
                    reference="User"
                  >
                    <FunctionField
                      label="Debtor"
                      render={(record: any) => {
                        setEmailTitle(record?.email ? "Email" : "Username");

                        if (record?.email) {
                          return <UserEmail />;
                        }

                        return <span>{record?.username}</span>;
                      }}
                    />
                  </ReferenceField>
                }
              ></ListItemText>
            </ListItem>
            <Divider component="li" />

            <ListItem alignItems={"flex-start"}>
              <ListItemText
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                primary={<Typography variant="caption">{"Phone:"}</Typography>}
                secondary={
                  <ReferenceField
                    link={false}
                    label="Debtor"
                    source="debtor.id"
                    reference="User"
                  >
                    <PhoneNumberComponent inline />
                  </ReferenceField>
                }
              ></ListItemText>
            </ListItem>
            <Divider component="li" />
            <ListItem alignItems={"flex-start"}>
              <ListItemText
                secondary={
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography variant="caption" color="textPrimary">
                        Address:
                      </Typography>
                    }
                    secondary={
                      <ListItemText
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        primary={
                          <ReferenceField
                            link=""
                            label="Debtor"
                            source="debtor.id"
                            reference="User"
                          >
                            <ReferenceField
                              link="show"
                              label="Address"
                              source="address.id"
                              reference="Address"
                            >
                              <>
                                <TextField source={"addressLine1"} />
                                ,&nbsp;
                                <TextField source={"postalcode"} />
                                &nbsp;
                                <TextField source={"town"} />
                                ,&nbsp;
                                <FunctionField
                                  label=""
                                  render={(record: any) => {
                                    if (record?.country && !userCountry) {
                                      setUserCountry(record.country);
                                    }

                                    return (
                                      <span>
                                        {record?.country
                                          ? ADDRESS_COUNTRY_READABLE.get(
                                              record.country
                                            )
                                          : ""}
                                      </span>
                                    );
                                  }}
                                />
                                <FunctionField
                                  label=""
                                  render={(record: any) => {
                                    if (!record.country) {
                                      return null;
                                    }

                                    const iso2CoutryCode = searchMapByKey(
                                      ADDRESS_COUNTRY_SHORT,
                                      record.country as EnumAddressCountry
                                    );
                                    if (!iso2CoutryCode) {
                                      return null;
                                    }

                                    return (
                                      <ReactCountryFlag
                                        countryCode={iso2CoutryCode}
                                        svg
                                        style={{ marginLeft: 4 }}
                                      />
                                    );
                                  }}
                                />
                                <FunctionField
                                  label="Verification Status"
                                  render={(record: any) => (
                                    <span>
                                      {(!record.verificationStatus ||
                                        record.verificationStatus ===
                                          "UNKNOWN") && (
                                        <icons.Help
                                          style={{
                                            height: 15,
                                            color: theme.palette.warning.dark,
                                          }}
                                        />
                                      )}
                                      {record.verificationStatus ===
                                        "VALID" && (
                                        <icons.VerifiedUser
                                          style={{
                                            height: 15,
                                            color: theme.palette.success.main,
                                          }}
                                        />
                                      )}
                                      {record.verificationStatus ===
                                        "INVALID" && (
                                        <icons.RemoveCircle
                                          style={{
                                            height: 15,
                                            color: theme.palette.error.main,
                                          }}
                                        />
                                      )}
                                    </span>
                                  )}
                                />
                              </>
                            </ReferenceField>
                          </ReferenceField>
                        }
                      ></ListItemText>
                    }
                  ></ListItemText>
                }
              ></ListItemText>
            </ListItem>
          </MuiList>
        </Grid>
      </Grid>
      <Contacts
        hasLawyers={false}
        setHasLawyers={() => {}}
        hasInsolvencyAdministrators={false}
        setHasInsolvencyAdministrators={() => {}}
        userCountry={userCountry}
      />
    </>
  );
};
