export const stringToHslColor = (input, lightLevel) => {
  if (!input) {
    return "";
  }

  let hash = 0;
  for (let i = 0; i < input.length; i++) {
    hash = input.charCodeAt(i) + ((hash << 5) - hash);
  }
  const h = hash % 360;
  return "hsl(" + h + ", " + 75 + "%, " + lightLevel + "%)";
};

export const getInitials = (name: string) => {
  if (!name) return "";

  const nameParts = name.trim().split(" ");
  const initials = nameParts
    .slice(0, 2)
    .map((part) => part[0].toUpperCase())
    .join("");

  return initials;
};
