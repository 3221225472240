import React from "react";

const DEFAULT_TITLE: string = "Debtist";

export const useTitle = (title: string) => {
  const documentDefined = typeof document !== "undefined";
  const originalTitle = React.useRef(documentDefined ? document.title : null);

  React.useEffect(() => {
    if (!documentDefined) return;

    const currentTitle = originalTitle.current;

    if (document.title !== title) {
      document.title = title + " | " + DEFAULT_TITLE;
    }

    return () => {
      document.title = currentTitle || "";
    };
  }, [documentDefined, title]);
};
