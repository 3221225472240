import { getReadableResult } from "../api/activity/Activity";
import { CLAIM_TYPES } from "../api/claim/Claim";

export const DATE_REGEX = /(\d{2}-\d{2}-\d{4})/;

export const startsWithAnyTwoLetters = (str: string): Boolean => {
  return /^[A-Z]{2}/.test(str.toUpperCase());
};

export const calculateTaxValue = (netValue: number, taxRate: number) => {
  return parseFloat((Math.round(netValue * 100 * taxRate) / 100).toFixed(2));
};

export function getClaimTypeTitle(key: string): string {
  const claim = CLAIM_TYPES.find((claim) => claim.key === key);
  return claim ? claim.title : "";
}

export const ActivityResultsForOutbound = [
  { label: getReadableResult("NotReached"), value: "NotReached" },
  { label: getReadableResult("Reached"), value: "Reached" },
  {
    label: getReadableResult("ReachedPromisedToPay"),
    value: "ReachedPromisedToPay",
  },
  {
    label: getReadableResult("ReachedIgnored"),
    value: "ReachedIgnored",
  },
  {
    label: getReadableResult("ReachedRefusedToPay"),
    value: "ReachedRefusedToPay",
  },
  {
    label: getReadableResult("ReachedRefusedCorrectness"),
    value: "ReachedRefusedCorrectness",
  },
  {
    label: getReadableResult("ReachedPaymentPlanSetup"),
    value: "ReachedPaymentPlanSetup",
  },
  {
    label: getReadableResult("ReachedPaymentPlanAdjustment"),
    value: "ReachedPaymentPlanAdjustment",
  },
  {
    label: getReadableResult("ReachedPaymentPlanStop"),
    value: "ReachedPaymentPlanStop",
  },
];

export const ActivityResultsForDecisions = [
  { label: getReadableResult("Positive"), value: "Positive" },
  { label: getReadableResult("Negative"), value: "Negative" },
];
