import { colors, Grid } from "@material-ui/core";
import { debounce } from "lodash";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  DateTimeInput,
  DeleteWithConfirmButton,
  Edit,
  EditProps,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  Toolbar,
  useDataProvider,
  useGetIdentity,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { useForm, useFormState } from "react-final-form";
import { Mention, MentionsInput } from "react-mentions";
import { useHistory } from "react-router-dom";
import { getReadableSubjectByClaimAction } from "../api/activity/Activity";
import { ClaimTitle } from "../claim/ClaimTitle";
import { DEV_USERS } from "../config/AppConfig";
import {
  ActivityResultsForDecisions,
  ActivityResultsForOutbound,
} from "../util/ClaimUtils";
import { getInitials } from "../util/StyleUtils";
import { getFormattedActivityTypeChoices } from "./ActivityCreate";

let INITIAL_DATE: Date | undefined;

const ResultInput = () => {
  const { values } = useFormState();
  const form = useForm();

  return (
    <SelectInput
      source="result"
      label="Result"
      onChange={(e) => {
        if (e.target.value) {
          form.change("activityDate", new Date());
          form.change("isPlanned", false);
        }
      }}
      choices={
        !values.activityType ||
        values.activityType?.startsWith("Outbound") ||
        values.activityType === "Response"
          ? ActivityResultsForOutbound
          : ActivityResultsForDecisions
      }
      optionText="label"
      allowEmpty
      optionValue="value"
    />
  );
};

const ClaimActionInput = (): React.ReactElement => {
  const { values } = useFormState();

  return (
    <SelectInput
      disabled={
        (!values.activityType ||
          !values.activityType?.startsWith("Outbound")) &&
        !values.isPlanned
      }
      source="claimAction"
      label="Claim Action"
      choices={[
        {
          label: getReadableSubjectByClaimAction("FirstDunning"),
          value: "FirstDunning",
        },
        {
          label: getReadableSubjectByClaimAction("SecondDunning"),
          value: "SecondDunning",
        },
        {
          label: getReadableSubjectByClaimAction("ThirdDunning"),
          value: "ThirdDunning",
        },
        {
          label: getReadableSubjectByClaimAction("FourthDunning"),
          value: "FourthDunning",
        },
        {
          label: getReadableSubjectByClaimAction("FifthDunning"),
          value: "FifthDunning",
        },
        {
          label: getReadableSubjectByClaimAction("SixthDunning"),
          value: "SixthDunning",
        },
        {
          label: getReadableSubjectByClaimAction("FirstReminder"),
          value: "FirstReminder",
        },
        {
          label: getReadableSubjectByClaimAction("SecondReminder"),
          value: "SecondReminder",
        },
        {
          label: getReadableSubjectByClaimAction("ThirdReminder"),
          value: "ThirdReminder",
        },
        {
          label: getReadableSubjectByClaimAction("PhoneResearch"),
          value: "PhoneResearch",
        },
        {
          label: getReadableSubjectByClaimAction("EmailResearch"),
          value: "EmailResearch",
        },
        {
          label: getReadableSubjectByClaimAction("OnlineResearch"),
          value: "OnlineResearch",
        },
        {
          label: getReadableSubjectByClaimAction("PublicAuthorityRequest"),
          value: "PublicAuthorityRequest",
        },
        {
          label: getReadableSubjectByClaimAction("DefaultSummon"),
          value: "DefaultSummon",
        },
        {
          label: getReadableSubjectByClaimAction("Lawsuit"),
          value: "Lawsuit",
        },
        {
          label: getReadableSubjectByClaimAction("DebtCollectionTransfer"),
          value: "DebtCollectionTransfer",
        },
        {
          label: getReadableSubjectByClaimAction("DiscountOffer"),
          value: "DiscountOffer",
        },
      ]}
      optionText="label"
      allowEmpty
      optionValue="value"
    />
  );
};

const ActivityDateInput = (): React.ReactElement => {
  const { values } = useFormState();
  if (values.activityDate && !INITIAL_DATE) {
    INITIAL_DATE = new Date(values.activityDate);
  }

  return (
    <DateTimeInput
      label="Activity Date"
      source="activityDate"
      disabled={!values.isPlanned && values.claimAction}
      onChange={(event) => {
        INITIAL_DATE = new Date(event?.target?.value);
      }}
    />
  );
};

const IsPlannedInput = (): React.ReactElement => {
  const { values } = useFormState();
  const form = useForm();

  if (values.activityType?.startsWith("Outbound") && values.claimAction) {
    return (
      <BooleanInput
        disabled
        label="Is Planned"
        source="isPlanned"
        style={{ display: "flex", justifySelf: "flex-end", marginTop: 20 }}
      />
    );
  } else {
    return (
      <BooleanInput
        label="Is Planned"
        source="isPlanned"
        style={{ display: "flex", justifySelf: "flex-end", marginTop: 20 }}
        onChange={(value) => {
          if (!value) {
            // update to current date
            form.change("activityDate", new Date());
          } else if (INITIAL_DATE) {
            form.change("activityDate", INITIAL_DATE);
          }
        }}
      />
    );
  }
};

export const ActivityEdit = (props: EditProps): React.ReactElement => {
  const history = useHistory();
  const notify = useNotify();
  const { identity } = useGetIdentity();
  const dataProvider = useDataProvider();
  const [employees, setEmployees] = useState([]);
  const refresh = useRefresh();

  useEffect(() => {
    const fetchEmployees = async () => {
      const { data } = await dataProvider.getList("User", {
        filter: {
          businessName: { equals: "Debtist GmbH" },
          roles: { array_contains: "manager" },
        },
        pagination: { page: 1, perPage: 100 },
        sort: { field: "contactName", order: "ASC" },
      });
      setEmployees(
        data.map((employee) => ({
          id: employee.id,
          display: employee.contactName,
        }))
      );
    };
    fetchEmployees();
  }, [dataProvider]);

  const handleAddAssignee = async (claimId: string, assigneeId: string) => {
    try {
      const updateClaim = await dataProvider.update("Claim", {
        id: claimId,
        data: { assignee: assigneeId ? { id: assigneeId } : null },
        previousData: { id: claimId },
      });

      if (updateClaim) {
        notify("User assigned successfully", { type: "success" });
        refresh();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleKeyPress = useCallback(
    (event) => {
      if (event.target === document.body && event.key === "e") {
        window.open("/#/Activity/" + props.id + "/show", "_self");
      }
    },
    [props.id]
  );

  const fetchUsers = debounce((query, callback) => {
    if (!query) return;
    const filteredEmployees = employees.filter((employee) =>
      employee.display.toLowerCase().includes(query.toLowerCase())
    );
    callback(filteredEmployees);
  }, 300);

  useEffect(() => {
    // attach the event listener
    document.addEventListener("keydown", handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  const CommentInput = () => {
    const { values } = useFormState();
    const form = useForm();

    const handleCommentChange = useCallback(
      (event, newValue, newPlainTextValue, mentions) => {
        form.change("comment", newValue);

        if (mentions.length > 0) {
          form.change("activityType", "InternalDecision");
        }
      },
      [form]
    );

    return (
      <div className="topGInput">
        <MentionsInput
          value={values.comment || ""}
          onChange={handleCommentChange}
          className="topG"
        >
          <Mention
            appendSpaceOnAdd
            trigger="@"
            data={fetchUsers}
            renderSuggestion={(suggestion) => {
              return (
                <div className="suggestion">
                  <div className="active-user">
                    {getInitials(suggestion.display)}
                  </div>
                  {suggestion.display}
                </div>
              );
            }}
            onAdd={(mention) => {
              handleAddAssignee(values.claim?.id, mention.toString());
            }}
          />
        </MentionsInput>
      </div>
    );
  };

  return (
    <Edit
      {...props}
      onSuccess={() => {
        notify("Success", "info", null, true);
        history.go(-1);
      }}
      mutationMode="pessimistic"
    >
      <SimpleForm
        toolbar={
          <Toolbar className="elevated-card">
            <DeleteWithConfirmButton
              undoable={false}
              mutationMode="pessimistic"
              label="Delete"
              variant="outlined"
              onSuccess={() => {
                notify("Success", "info", null, true);
                history.goBack();
              }}
              style={{
                borderColor: colors.red[400],
                padding: "6px 16px",
              }}
            />
            <SaveButton label="Save" />
          </Toolbar>
        }
      >
        <Grid container spacing={2} style={{ width: "100%" }}>
          <Grid item xs={12} md={6}>
            <ActivityDateInput />
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectInput
              required
              source="activityType"
              label="Activity Type"
              choices={getFormattedActivityTypeChoices()}
              optionText="label"
              optionValue="value"
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <ResultInput />
          </Grid>
          <Grid item xs={12} md={6}>
            <ClaimActionInput />
          </Grid>

          <Grid item xs={12}>
            <CommentInput />
            {DEV_USERS.includes(identity?.username) && (
              <>
                <TextInput label="File Url" fullWidth source="fileUrl" />
                <ReferenceInput
                  filterToQuery={(searchText) => ({
                    reference: { startsWith: searchText },
                  })}
                  isRequired
                  source="claim.id"
                  reference="Claim"
                  label="Claim"
                >
                  <AutocompleteInput
                    suggestionLimit={5}
                    optionText={ClaimTitle}
                  />
                </ReferenceInput>
              </>
            )}

            <ParsedArrayInput source="fileUrls" />
          </Grid>
        </Grid>

        <IsPlannedInput />
      </SimpleForm>
    </Edit>
  );
};

const ParsedArrayInput = ({ source }) => {
  const record = useRecordContext();
  const [parsedValue, setParsedValue] = useState<String[]>([]);
  const form = useForm();

  useEffect(() => {
    if (record && record[source]) {
      let value = record[source];
      // Parse JSON if it's a string
      if (typeof value === "string") {
        try {
          const parsed = JSON.parse(value);

          if (Array.isArray(parsed)) {
            setParsedValue(parsed);
          }
        } catch (error) {
          console.error("Failed to parse JSON:", error);
        }
      } else if (Array.isArray(value)) {
        setParsedValue(value);
      }
    }
  }, [record, source]);

  if (!parsedValue?.length) {
    return null;
  }

  form.change("fileUrls", parsedValue);

  return (
    <ArrayInput source={source} defaultValue={parsedValue}>
      <SimpleFormIterator>
        <TextInput source="" label="File URL" />
      </SimpleFormIterator>
    </ArrayInput>
  );
};
