import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
// @ts-ignore
// eslint-disable-next-line import/no-unresolved
import App from "./App";
import { PhoneProvider } from "./context/PhoneContext";

ReactDOM.render(
  <React.StrictMode>
    <PhoneProvider>
      <App />
    </PhoneProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
