import {
  Activity,
  getReadableSubjectByClaimAction,
} from "../api/activity/Activity";
import { Payment } from "../api/payment/Payment";
import { PaymentPlan } from "../api/paymentPlan/PaymentPlan";

export const getStatusToShow = (
  claim: any,
  activities: Activity[],
  paymentPlans: PaymentPlan[],
  payments: Payment[]
) => {
  let statusToShow = claim?.status || "";

  switch (statusToShow) {
    case "EncashmentLevel":
      if (paymentPlans?.length && paymentPlans[0].isActive) {
        statusToShow = "PaymentPlan";
      } else if (activities?.length) {
        const firstActivityWithClaimAction = activities.find(
          (activity) =>
            !activity.isPlanned &&
            activity.claimAction &&
            (activity.claimAction.endsWith("Dunning") ||
              activity.claimAction.endsWith("Reminder")) &&
            activity.result !== "Negative"
        );

        if (
          firstActivityWithClaimAction &&
          firstActivityWithClaimAction.claimAction
        ) {
          statusToShow = firstActivityWithClaimAction.claimAction;
        }
      }
      break;
    case "Open":
    case "ReminderLevel":
    case "JudicialLevel":
      break;
    case "Closed":
    case "Paid":
      if (
        payments?.filter(
          (payment) => payment.paymentType === "Payout" && !payment.isPlanned
        ).length
      ) {
        statusToShow = "PaidOut";
      }
      break;
    default:
      break;
  }

  return getReadableSubjectByClaimAction(statusToShow);
};
