import * as React from "react";

import { Grid } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import {
  AutocompleteInput,
  BooleanInput,
  DateInput,
  DeleteWithConfirmButton,
  Edit,
  EditProps,
  NumberInput,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  maxValue,
  minValue,
  useGetIdentity,
  useNotify,
} from "react-admin";
import { useFormState } from "react-final-form";
import { useHistory } from "react-router-dom";
import {
  EnumPaymentPaymentType,
  NEGATIVE_PAYMENT_TYPES,
} from "../api/payment/EnumPaymentPaymentType";
import { ClaimTitle } from "../claim/ClaimTitle";

const AmountInput = () => {
  const { values } = useFormState();

  if (
    values.amount === undefined ||
    values.amount === "" ||
    values.amount === "-"
  ) {
    if (
      NEGATIVE_PAYMENT_TYPES.includes(
        values.paymentType as EnumPaymentPaymentType
      )
    ) {
      values.amount = "-";
    } else {
      values.amount = "";
    }
  }

  return (
    <NumberInput
      label="Amount (Use '-' for credits)"
      source="amount"
      required
      // eslint-disable-next-line no-self-assign
      onFocus={(e) => (e.target.value = e.target.value)}
      validate={
        values.paymentType === "Payout"
          ? null
          : NEGATIVE_PAYMENT_TYPES.includes(
              values.paymentType as EnumPaymentPaymentType
            )
          ? maxValue(0)
          : minValue(0)
      }
    />
  );
};

const IsPlannedInput = () => {
  const [manualMode, setManualMode] = useState(false);
  const { values } = useFormState();

  if (values.fileUrl && !manualMode) {
    values.isPlanned = false;
  }

  return (
    <BooleanInput
      label="Is planned"
      source="isPlanned"
      onChange={() => {
        setManualMode(true);
      }}
      style={{ display: "inline" }}
    />
  );
};

export const PaymentEdit = (props: EditProps): React.ReactElement => {
  const history = useHistory();
  const notify = useNotify();
  const { identity } = useGetIdentity();

  const handleKeyPress = useCallback(
    (event) => {
      if (event.target === document.body && event.key === "e") {
        window.open("/#/Payment/" + props.id + "/show", "_self");
      }
    },
    [props.id]
  );

  useEffect(() => {
    // attach the event listener
    document.addEventListener("keydown", handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <Edit
      {...props}
      onSuccess={() => {
        notify("Success", "info", null, true);
        history.go(-2);
      }}
      mutationMode="pessimistic"
    >
      <SimpleForm
        toolbar={
          <Toolbar
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <SaveButton label="Save" />
            <DeleteWithConfirmButton
              undoable={false}
              style={{ border: 0 }}
              mutationMode="pessimistic"
              label="Delete"
              variant="outlined"
              onSuccess={() => {
                notify("Success", "info", null, true);
                history.goBack();
              }}
            />
          </Toolbar>
        }
      >
        <Grid container spacing={2} style={{ width: "100%" }}>
          <Grid item xs={12} md={6}>
            <DateInput label="Payment Date" source="paymentDate" required />
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectInput
              source="paymentType"
              label="Payment Type"
              required
              choices={[
                { label: "DunningCostExpense", value: "DunningCostExpense" },
                { label: "DunningCostFee", value: "DunningCostFee" },
                { label: "DefaultInterest", value: "DefaultInterest" },
                {
                  label: "ExistingCreditorExtras",
                  value: "ExistingCreditorExtras",
                },
                {
                  label: "ExistingPayments",
                  value: "ExistingPayments",
                },
                { label: "WriteOffDiscount", value: "WriteOffDiscount" },
                { label: "DebtClearance", value: "DebtClearance" },
                { label: "PaymentRate", value: "PaymentRate" },
                { label: "Payout", value: "Payout" },
                { label: "CreditorExpenses", value: "CreditorExpenses" },
                { label: "ClaimPosition", value: "ClaimPosition" },
                { label: "CreditorInterest", value: "CreditorInterest" },
                { label: "DebtCollectorTax", value: "DebtCollectorTax" },
                { label: "DebtCollectorFee", value: "DebtCollectorFee" },
                {
                  label: "DebtCollectorOutboundPosition",
                  value: "DebtCollectorOutboundPosition",
                },
                {
                  label: "DebtCollectorInboundPosition",
                  value: "DebtCollectorInboundPosition",
                },
              ]}
              optionText="label"
              allowEmpty
              optionValue="value"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <AmountInput />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              label="Reference (Example: Interest rate)"
              source="reference"
            />
          </Grid>
          {identity?.username === "b.baumgarten@debtist.de" && (
            <>
              <Grid item xs={12} md={6}>
                <TextInput label="ExternalId" source="externalId" />
                <TextInput label="File URL" source="fileUrl" />
                <TextInput label="Conversion Rate" source="conversionRate" />
              </Grid>
              <Grid item xs={12} md={6}>
                <ReferenceInput
                  filterToQuery={(searchText) => ({
                    reference: { startsWith: searchText },
                  })}
                  isRequired
                  source="claim.id"
                  reference="Claim"
                  label="Claim"
                >
                  <AutocompleteInput
                    suggestionLimit={5}
                    optionText={ClaimTitle}
                  />
                </ReferenceInput>
              </Grid>
            </>
          )}
        </Grid>

        <IsPlannedInput />
        <BooleanInput
          label="Is tax free"
          source="isTaxFree"
          defaultValue={false}
          style={{ display: "inline" }}
        />
      </SimpleForm>
    </Edit>
  );
};
