import { SimpleForm } from "react-admin";
import { ActivityCreate } from "../../activity/ActivityCreate";
import { NonInput } from "../NonInputComponent";

const AssignClaimForm = ({ claimId, setShow, refresh, assigneeId }) => {
  return (
    <SimpleForm toolbar={false}>
      <NonInput>
        <div
          style={{
            width: 500,
            flex: 1,
            alignItems: "stretch",
            flexDirection: "column",
            display: "flex",
          }}
        >
          <ActivityCreate
            basePath="/Activity"
            resource="Activity"
            options={{
              inline: true,
              claimId: claimId,
              assigneeId: assigneeId,
              assignAndInput: true,
              setShow: setShow,
              refresh: refresh,
            }}
          />
        </div>
      </NonInput>
    </SimpleForm>
  );
};

export default AssignClaimForm;
