import AlternateEmail from "@material-ui/icons/AlternateEmail";
import Mail from "@material-ui/icons/Mail";
import Phone from "@material-ui/icons/Phone";
import Sms from "@material-ui/icons/Sms";
import WhatsApp from "@material-ui/icons/WhatsApp";
import { useRecordContext } from "react-admin";
import {
  getReadableActivityType,
  getReadableResult,
  getReadableSubjectByClaimAction,
} from "../../api/activity/Activity";
import { theme } from "../../theme/theme";

const ActivityTypePresentation = () => {
  const record = useRecordContext();
  if (record?.activityType?.startsWith("Outbound")) {
    return (
      <span
        title="Planned letters"
        style={{
          padding: 2,
          color: theme.palette.secondary.main,
        }}
      >
        {record?.activityType === "OutboundLetter" && (
          <Mail
            style={{
              width: "auto",
              height: 15,
              verticalAlign: "middle",
              marginRight: 3,
              marginBottom: 2,
            }}
          />
        )}
        {record?.activityType === "OutboundMail" && (
          <AlternateEmail
            style={{
              width: "auto",
              height: 15,
              verticalAlign: "middle",
              marginRight: 3,
              marginBottom: 2,
            }}
          />
        )}
        {record?.activityType === "OutboundCall" && (
          <Phone
            style={{
              width: "auto",
              height: 15,
              verticalAlign: "middle",
              marginRight: 3,
              marginBottom: 2,
            }}
          />
        )}
        {record?.activityType === "OutboundSms" && (
          <Sms
            style={{
              width: "auto",
              height: 15,
              verticalAlign: "middle",
              marginRight: 3,
              marginBottom: 2,
            }}
          />
        )}
        {record?.activityType === "OutboundWhatsapp" && (
          <WhatsApp
            style={{
              width: "auto",
              height: 15,
              verticalAlign: "middle",
              marginRight: 3,
              marginBottom: 2,
            }}
          />
        )}
        {!record.claimAction && (
          <span style={{ fontWeight: "bold" }}>
            {getReadableActivityType(record?.activityType)}{" "}
          </span>
        )}
      </span>
    );
  } else {
    return (
      <span style={{ fontWeight: "bold", color: theme.palette.grey[800] }}>
        {getReadableActivityType(record?.activityType)}{" "}
      </span>
    );
  }
};

export const ActivitySummaryField = () => {
  const record = useRecordContext();

  return (
    <>
      <ActivityTypePresentation />
      {!!record?.claimAction && (
        <>
          <b style={{ color: theme.palette.secondary.main }}>
            {getReadableSubjectByClaimAction(record?.claimAction)}
          </b>
        </>
      )}
      {!!record?.result && (
        <div
          style={{
            fontWeight: "bold",
            color:
              record.result === "NotReached" || record.result === "Negative"
                ? theme.palette.error.main
                : record.result === "Positive"
                ? theme.palette.success.main
                : theme.palette.primary.main,
          }}
        >
          {getReadableResult(record?.result)}
        </div>
      )}
    </>
  );
};
