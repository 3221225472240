import { ApolloClient, InMemoryCache } from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { setContext } from "@apollo/client/link/context";
import buildGraphQLProvider from "ra-data-graphql-amplication";
import { CREDENTIALS_LOCAL_STORAGE_ITEM } from "../constants";

const httpLink = new BatchHttpLink({
  uri: `${process.env.REACT_APP_SERVER_URL}/graphql`,
  batchMax: 10, // Maximum number of operations per batch
  batchInterval: 20, // Time window in milliseconds to collect operations,
});

// eslint-disable-next-line @typescript-eslint/naming-convention
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(CREDENTIALS_LOCAL_STORAGE_ITEM);
  return {
    headers: {
      ...headers,
      authorization: token ? token : "",
    },
  };
});

// no way to clear the cache currently on edits / creations of related object.
export const cacheDataProvider = (dataProvider, duration = 60 * 1000) =>
  new Proxy(dataProvider, {
    get: (target, name) => (resource, params) => {
      if (
        (name === "getMany" || name === "getList") &&
        resource === "Address"
      ) {
        return dataProvider[name](resource, params).then((response) => {
          const validUntil = new Date();
          validUntil.setTime(validUntil.getTime() + duration);
          response.validUntil = validUntil;
          return response;
        });
      }
      return dataProvider[name](resource, params);
    },
  });

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});

export default buildGraphQLProvider({
  client: apolloClient,
});
