import React from "react";
import { RichTextField, useRecordContext } from "react-admin";

export const CommentTab: React.FC = () => {
  const record = useRecordContext();

  if (!record?.comment?.length) return null;

  return (
    <div
      style={{
        marginTop: 20,
        background: "#f5f5f5",
        padding: 20,
        borderRadius: 8,
      }}
    >
      <RichTextField
        label="Comment"
        source="comment"
        style={{ whiteSpace: "pre-wrap" }}
      />
    </div>
  );
};
